import React, { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AddConatiner = () => {
  const [Addcontainers, setAddcontainers] = useState({
    maxWeight: "",
    maxVolume: "",
    isFridge: "",
    usualName: "",
    containerType: "",
    storedMaterialProperty: [],
  });
  const handleMatrialProp = (e) => {
    if (e.target.checked) {
      setAddcontainers({
        ...Addcontainers,
        storedMaterialProperty: [
          ...Addcontainers.storedMaterialProperty,
          e.target.value,
        ],
      });
    } else {
      setAddcontainers({
        ...Addcontainers,
        storedMaterialProperty: Addcontainers.storedMaterialProperty.filter(
          (item) => item !== e.target.value
        ),
      });
    }
  };
  //==============================handleInputs=======================================

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setAddcontainers({ ...Addcontainers, [name]: value });
  };
  //===========================Add Containers=========================
  const navigate = useNavigate();
  const addContainer = async (e) => {
    try {
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      if (
        Addcontainers.maxVolume &&
        Addcontainers.maxWeight &&
        Addcontainers.isFridge &&
        Addcontainers.usualName &&
        Addcontainers.containerType &&
        Addcontainers.storedMaterialProperty
      ) {
        var raw = JSON.stringify({
          maxWeight: Addcontainers.maxWeight,
          maxVolume: Addcontainers.maxVolume,
          isFridge: Addcontainers.isFridge,
          usualName: Addcontainers.usualName,
          containerType: Addcontainers.containerType,
          storedMaterialProperty: Addcontainers.storedMaterialProperty,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/container/",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          alert("There Is An Error");
          console.log(body);
        } else {
          toast.success("Container Has Been Added");
          navigate(-1);
          setAddcontainers({
            maxWeight: "",
            maxVolume: "",
            isFridge: "",
            isHighcube: "",
            containerType: "",
            storedMaterialProperty: [],
          });
          window.location.reload();
        }
      } else {
        toast.error("Please Fill All The Data To Create A Container");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="create-container">
      <p style={{
        color: "black",
        fontSize: "14px"
      }}>Please enter per ton</p>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicmaxWeight">
          <Form.Label>Max Weight</Form.Label>
          <Form.Control
            type="text"
            name="maxWeight"
            placeholder="Max Weight"
            onChange={handleInputs}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicmaxVolume">
          <Form.Label>Max Volume</Form.Label>
          <Form.Control
            name="maxVolume"
            placeholder="Max Volume"
            onChange={handleInputs}
          />
        </Form.Group>
        <Form.Group>
          <Form.Group className="mb-3" controlId="formBasicmaxVolume">
            <Form.Label>Usual Name</Form.Label>
            <Form.Control
              name="usualName"
              placeholder="Usual Name"
              onChange={handleInputs}
            />
          </Form.Group>
          <Form.Label>Fridge ? </Form.Label>
          <Form.Select
            name="isFridge"
            onChange={handleInputs}
            aria-label="Default select example"
          >
            <option>Please Choose</option>

            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>Container Type</Form.Label>
          <Form.Select
            name="containerType"
            onChange={handleInputs}
            aria-label="Default select example"
          >
            <option>Please Choose Container Type</option>

            <option value={`land`}>Land</option>
            <option value={`sea`}>Sea</option>
            <option value={`air`}>Air</option>
          </Form.Select>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label>Stored Material Property</Form.Label>
          <Form.Check
            label="Dried"
            type={"checkbox"}
            value="dried"
            onChange={(e) => handleMatrialProp(e)}
          />
          <Form.Check
            label="Frozen"
            type={"checkbox"}
            value="frozen"
            onChange={(e) => handleMatrialProp(e)}
          />
          <Form.Check
            label="Mix"
            type={"checkbox"}
            value="mix"
            onChange={(e) => handleMatrialProp(e)}
          />
        </Form.Group>

        <Button variant="warning" onClick={addContainer}>
          Add Container
        </Button>
      </Form>
    </div>
  );
};

export default AddConatiner;
