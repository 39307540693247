import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const icons = [
  'State', 'Account', 'Rating', 'Agent', 'AboutUs', 'Product', 'ProductCat', 'Contact Us', 'Bites', 'Partners',
  'ProductPrice', 'Delivery-method', 'Activity', 'Offer', 'Containers', 'Countries', 'max-weight-container',
  'Kitchen', 'Admins', 'App Version', 'Pallet', 'Advertisements', 'Promotional Materials', 'Sessions', 'Shipment'
];

const mapPermissionsToRoles = (icon) => ({
  name: icon,
  create: false,
  read: false,
  update: false,
  delete: false,
  show: false,
  approveCreate: false,
  approveUpdate: false,
  approveDelete: false
});

const initialPermissions = icons.map(mapPermissionsToRoles);

const UpdateAdmins = () => {
  const [adminInfo, setAdminInfo] = useState({
    name: "",
    email: "",
    password: "",
    currentPassword: "",
    role: []
  });

  const [permissions, setPermissions] = useState(initialPermissions);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const adminToken = localStorage.getItem("admin-token");
  const decodedToken = jwt_decode(adminToken);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getAdminByIdForAdmin = async () => {
      const response = await fetch(`https://www.api.setalkel.co/admin/${params.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('admin-token')}`
        }
      });
      if (response.ok) {
        const body = await response.json();
        setAdminInfo({
          name: body.name,
          email: body.email,
          role: body.role
        });
        setSelectedPermissions(body.role);
        const updatedPermissions = permissions.map(icon => ({
          ...icon,
          create: body.role.includes(`c-${icon.name.toLowerCase()}`),
          read: body.role.includes(`r-${icon.name.toLowerCase()}`),
          update: body.role.includes(`u-${icon.name.toLowerCase()}`),
          delete: body.role.includes(`d-${icon.name.toLowerCase()}`),
          show: body.role.includes(`s-${icon.name.toLowerCase()}`),
          approveCreate: body.role.includes(`ac-${icon.name.toLowerCase()}`),
          approveUpdate: body.role.includes(`au-${icon.name.toLowerCase()}`),
          approveDelete: body.role.includes(`ad-${icon.name.toLowerCase()}`)
        }));
        setPermissions(updatedPermissions);
      }
    };
    getAdminByIdForAdmin();
  }, [params.id]);

  const handleCheckboxChange = (iconName, permission) => {
    setPermissions(prevPermissions =>
      prevPermissions.map(icon =>
        icon.name === iconName
          ? { ...icon, [permission]: !icon[permission] }
          : icon
      )
    );

    const permissionShortcuts = {
      create: 'c',
      read: 'r',
      update: 'u',
      delete: 'd',
      show: 's',
      approveCreate: 'ac',
      approveUpdate: 'au',
      approveDelete: 'ad'
    };

    const permissionString = `${permissionShortcuts[permission]}-${iconName.toLowerCase()}`;

    setSelectedPermissions(prevSelected => {
      if (prevSelected.includes(permissionString)) {
        return prevSelected.filter(p => p !== permissionString);
      } else {
        return [...prevSelected, permissionString];
      }
    });
  };

  const handleInfo = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };
  const updateAdminPassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      currentPassword: adminInfo.currentPassword,
      password: adminInfo.password,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (adminInfo.currentPassword && adminInfo.password) {
      const resposne = await fetch(
        "https://www.api.setalkel.co/admin/reset-password",
        requestOptions
      );
      if (!resposne.ok) {
        alert("Password Not Updated");
        console.log(resposne);
      } else {
        alert("Password Has Been Changed Please Keep It Safe");
        window.location.reload();
      }
    }
  };
  const updateAdminInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: adminInfo.name,
      email: adminInfo.email,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/admin/update-info",
      requestOptions
    );
    if (!response.ok) {
      alert("Admin Info Not Updated");
      console.log(response);
    } else {
      adminInfo.currentPassword && adminInfo.password && updateAdminPassword();
      alert("admin Info Has been Updated Successfully");
      navigate("/dashboard/cradmins");
      window.location.reload();
    }
  };
  const updateAdminInfoBySuperAdmin = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: adminInfo.name,
      email: adminInfo.email,
      role: selectedPermissions,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (params.id) {
      const response = await fetch(
        `https://www.api.setalkel.co/admin/update-info-from-admin/${params.id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        alert("Admin Info Not Updated");
        console.log(body);
      } else {
        adminInfo.currentPassword &&
          adminInfo.password &&
          updateAdminPassword();
        alert("admin Info Has been Updated Successfully");
        navigate("/dashboard/cradmins");
        window.location.reload();
      }
    } else {
      updateAdminInfo();
    }
  };

  const selectAllPermissions = () => {
    const updatedPermissions = permissions.map(icon => ({
      ...icon,
      create: true,
      read: true,
      update: true,
      delete: true,
      show: true,
      approveCreate: true,
      approveUpdate: true,
      approveDelete: true
    }));
    setPermissions(updatedPermissions);

    const allPermissions = updatedPermissions.flatMap(icon => [
      `c-${icon.name.toLowerCase()}`,
      `r-${icon.name.toLowerCase()}`,
      `u-${icon.name.toLowerCase()}`,
      `d-${icon.name.toLowerCase()}`,
      `s-${icon.name.toLowerCase()}`,
      `ac-${icon.name.toLowerCase()}`,
      `au-${icon.name.toLowerCase()}`,
      `ad-${icon.name.toLowerCase()}`
    ]);
    setSelectedPermissions(allPermissions);
  };

  const deselectAllPermissions = () => {
    const updatedPermissions = permissions.map(icon => ({
      ...icon,
      create: false,
      read: false,
      update: false,
      delete: false,
      show: false,
      approveCreate: false,
      approveUpdate: false,
      approveDelete: false
    }));
    setPermissions(updatedPermissions);
    setSelectedPermissions([]);
  };

  return (
    <div className="container mt-4">
      <h1>Update Admin</h1>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={adminInfo.name}
            placeholder="Enter User Name"
            onChange={handleInfo}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={adminInfo.email}
            placeholder="Enter Admin email"
            onChange={handleInfo}
            required
          />
        </Form.Group>
      </Form>
      <h6>Do You Want To Chagne Your Password ? </h6>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          type="password"
          name="currentPassword"
          placeholder="Enter Old Password"
          required
          value={adminInfo.currentPassword}
          onChange={handleInfo}
        />
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Enter New Password"
          required
          value={adminInfo.password}
          onChange={handleInfo}
        />
      </Form.Group>
      <div className="mb-3 d-flex gap-2">
        <Button variant="primary" onClick={selectAllPermissions}>
          Select All
        </Button>
        <Button variant="danger" onClick={deselectAllPermissions}>
          Cancel All
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Icon Name</th>
            <th>Read</th>
            <th>Show</th>
            <th>Create</th>
            <th>Approve Create</th>
            <th>Update</th>
            <th>Approve Update</th>
            <th>Delete</th>
            <th>Approve Delete</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map(icon => (
            <tr key={icon.name}>
              <td>{icon.name}</td>
              {['read', 'show', 'create', 'approveCreate', 'update', 'approveUpdate', 'delete', 'approveDelete'].map(permission => (
                <td key={permission}>
                  <Form.Check
                    type="checkbox"
                    checked={icon[permission]}
                    onChange={() => handleCheckboxChange(icon.name, permission)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <Button variant="warning" onClick={updateAdminInfoBySuperAdmin}>
        Update Admin
      </Button>
    </div>
  );
};

export default UpdateAdmins;
