import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import { FaArrowCircleRight, FaArrowCircleLeft, FaDownload } from "react-icons/fa";
//boostrap
import { Row, Button, Form, Container, Modal } from "react-bootstrap";
//css
import "./CrudProducts.css";
//semantic ui
import { Table } from "semantic-ui-react";

import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";

import { ARRAYOFCountries } from "./CountriesArray";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
const ProductTable = () => {
  const [TotalmetaData, setTotalMetaData] = useState("");
  const [CategoryMetaData, setCategoryMetaData] = useState("");
  const [pieceQTYModalShow, setPieceQTYModalShow] = React.useState(false);
  const [productNamesModalShow, setProductNamesModalShow] =
    React.useState(false);
  const [productId, setProductId] = useState("");
  const [generatedExcelFile, setGeneratedExcelFile] = useState({});
  const [packageModalShow, setPackageModalShow] = React.useState(false);
  const [choosenProduct, setChoosenProduct] = useState();
  const [CatFilterID, setCatFilterID] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShowClose = () => setDeleteModalShow(false);
  const handleDeleteModalShowShow = () => setDeleteModalShow(true);
  //===================================Mutliple Delete Products====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  const [successDelete, setSuccesDelet] = useState(false)
  const [numProductsALl, setnumProductAll] = useState(0)

  const [deletedProducts, setDeletedProducts] = useState({
    products: [],
  });
  const getFile = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      `https://www.api.setalkel.co${generatedExcelFile.pathFile}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${generatedExcelFile.pathFile}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const getFileImg = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const generateExcelFile = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/product/export",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setGeneratedExcelFile({});
    } else {
      setGeneratedExcelFile(body);
    }
  };
  //====================================SORTING========================================

  const filterProductsByCategoryID = async () => {
    if (CatFilterID) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${CatFilterID}`,
        requestOptions
      );
      const body = await response.json();
      // console.log(body);
      if (!response.ok) {
        setCategoryProducts([]);
        console.log(body);
      } else {
        setCategoryProducts(body.data);
        setCategoryMetaData(body._metadata);
        // console.log(body)
      }
    }
  };
  const handleSortingChange = (e) => {
    if (e.target.checked) {
      if (e.target.id === "A-Z") {
        const strAscending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? 1 : -1
        );
        setCategoryProducts(strAscending);
      } else if (e.target.id === "Z-A") {
        const strDescending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? -1 : 1
        );
        setCategoryProducts(strDescending);
      }
    }
  };
  //====================================SORTING========================================

  const handleWeightSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "1-100") {
        const numAscending = [...categoryProducts].sort(
          (a, b) =>
            a.peiceQty.grossWeightForPeice - b.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numAscending);
      } else if (e.target.id === "100-1") {
        const numDescending = [...categoryProducts].sort(
          (a, b) =>
            b.peiceQty.grossWeightForPeice - a.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numDescending);
      }
    }
  };
  //====================================SORTING========================================

  const handleMatriealSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "dried") {
        const driedData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${CatFilterID}&materialProperty=dried`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        driedData();
      } else if (e.target.id === "mix") {
        const mixData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${CatFilterID}&materialProperty=mix`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        mixData();
      } else if (e.target.id === "frozen") {
        const frozenData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${CatFilterID}&materialProperty=frozen`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        frozenData();
      }
    }
  };
  //====================================END  SORTING========================================
  const [page, setPage] = useState(1)
  useEffect(() => {
    filterProductsByCategoryID();
  }, [CatFilterID, page, successDelete === true]);
  useEffect(() => {
    if (deletedProducts.products.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedProducts.products]);

  //=============================================================Product Modals===============================================================




  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <div className="d-flex position-relative">
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
          <FaDownload onClick={() => getFileImg(row.img)} style={{
            color: "red",
            fontSize: "15px",
            position: "absolute",
            top: "0",
            right: "0"

          }} />
        </div>
      ),
    },
    {
      name: "Product",
      selector: (row) => row.subCat.en,
    },

    {
      name: "Usual Weight",
      selector: (row) => row.peiceQty.usualWeight,
    },

    {
      name: "Country Origin",
      selector: (row) => row.countryOrigin ? row.countryOrigin : "No Country Origin",
    },
    {
      name: "Material Property",
      selector: (row) => row.materialProperty ? row.materialProperty : "No Material Property"
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crproducts/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShowShow();
              setProductId(row._id);
            }}
          >
            Delete
          </Button>


        </>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const deleteProducts = (e, productId) => {
    if (e.target.checked) {
      if (!deletedProducts.products.includes(productId)) {
        setDeletedProducts({
          ...deletedProducts,
          products: [...deletedProducts.products, productId],
        });
      }
    } else {
      setDeletedProducts({
        products: deletedProducts.products.filter((item) => item !== productId),
      });
    }
  };
  //=======================delete Multiple Products========================
  const deleteMultipleProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/product/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("There Is An Error");
      // console.log(response);
    } else {
      toast.success("Products Have Been Deleted !!");
    }
  };

  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/product/${productId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          // console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleDeleteModalShowClose()
          setSuccesDelet(true)

        }
      })
      .catch((error) => {
        toast.error(error.response);
        setSuccesDelet(false)
      });
  };
  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCategories([]);
        // console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/meta-data/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setnumProductAll(0);
        // console.log(body);
      } else {
        setnumProductAll(body);
      }
    };
    fetchCategories();
  }, []);

  const navigate = useNavigate()
  return (
    <div>
      <div className="crud-title">
        <h1>Products</h1>

      </div>

      <div className="p-0">
        <Row>
          <Form.Group className="filter-category">
            <Form.Label>Filter Category</Form.Label>
            <Form.Select
              id="category-list"
              name="catFilterId"
              value={CatFilterID}
              onChange={(e) => setCatFilterID(e.target.value)}
            >
              <option>Please Choose A Category</option>
              {categories.map((category) => {
                return <option value={category._id}>{category.en}</option>;
              })}
            </Form.Select>



          </Form.Group>
          <div className="filters">

            <div>
              <h4>Alphabetically Sorting</h4>
              <Form.Check
                type="radio"
                name="sort"
                id="A-Z"
                label={<span>Sorting From A-Z</span>}
                onChange={(e) => handleSortingChange(e)}
              />
              <Form.Check
                type="radio"
                name="sort"
                id="Z-A"
                label={<span>Sorting From Z-A</span>}
                onChange={(e) => handleSortingChange(e)}
              />
            </div>
            <div>
              <h4>Weight Sorting</h4>
              <Form.Check
                type="radio"
                name="sort-weight"
                id="1-100"
                label={<span>Sorting From 1-100</span>}
                onChange={(e) => handleWeightSorting(e)}
              />
              <Form.Check
                type="radio"
                name="sort-weight"
                id="100-1"
                label={<span>Sorting From 100-1</span>}
                onChange={(e) => handleWeightSorting(e)}
              />
            </div>
            <div>
              <h4>Matrial Property Sorting</h4>
              <h6 style={{ color: "red" }}>Note : Choose Category First</h6>
              <Form.Check
                type="radio"
                name="sort-matrial-property"
                id="dried"
                label={<span>Dried</span>}
                onChange={(e) => handleMatriealSorting(e)}
              />
              <Form.Check
                type="radio"
                name="sort-matrial-property"
                id="mix"
                label={<span>MIX</span>}
                onChange={(e) => handleMatriealSorting(e)}
              />
              <Form.Check
                type="radio"
                name="sort-matrial-property"
                id="frozen"
                label={<span>Frozen</span>}
                onChange={(e) => handleMatriealSorting(e)}
              />
            </div>
          </div>



          <hr />
          <div className="list-product">
            <div className="list-info">
            <span>{`Total Number Of Products: ${numProductsALl?.count}`}</span><br/>
              <span>{`Total Number Of Products  in This Category: ${CategoryMetaData?.count ? CategoryMetaData?.count : '0'}`}</span><br/>
              <span>{`Total Number Of Products  in This Category is hidden: ${categoryProducts?.filter(item => item.hidden === true).length}`}</span><br/>
              <span>{`Total Number Of Products  in This Category is show: ${categoryProducts?.filter(item => item.hidden === false).length}`}</span>


              <br />


              <div className="btn">
                {generatedExcelFile.pathFile ? (
                  <Button variant="success" onClick={() => getFile()}>
                    Download All Products As Excel
                  </Button>
                ) : (
                  <Button onClick={() => generateExcelFile()}>
                    Generate All Products As Excel
                  </Button>
                )}
                <Button variant="danger" id="verify-btn" >
                  Import Excel
                </Button>
                <Button onClick={() => navigate("add-prod")} variant="warning">
                  Add Product
                </Button>
              </div>
              <Button
                id="multipleDelete"
                variant="outline-danger"
                onClick={() => handleMultipleShow()}
                className="m-0"
              >
                Multiple Delete
              </Button>
              <Modal show={DeleteModalShow} onHide={handleDeleteModalShowClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Are You Sure You Want To Delete This Product
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleDeleteModalShowClose}>
                    Close
                  </Button>
                  <Button variant="danger" onClick={() => onDelete()}>
                    Delete Product
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="lg"
                show={MultipleDeleteModalShow}
                onHide={() => setMultipleDeleteModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Are You Sure You Want To Delete All Those Products
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleMultipleClose}>
                    Close
                  </Button>
                  <Button variant="danger" onClick={deleteMultipleProducts}>
                    Delete Products
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Row>
        <hr />
        <Row>
          <DataTable
            fixedHeader
            responsive
            subHeaderWrap
            columns={columns}
            data={categoryProducts}

            customStyles={customStyles}
            highlightOnHover
            noDataComponent={<p>Please Select Category</p>}
          />

        </Row>
      </div>
    </div>
  )
}

export default ProductTable