import React from 'react'
import { useTranslation } from 'react-i18next'
import AddImg from "../../assets/BLOG2.jpg"
const Blog = () => {
  const { t } = useTranslation()
  return (
    <div className='address'>
      <img src={AddImg} style={{
        width:"95%",
        borderRadius:"15px"
      }}/>
    </div>
  )
}

export default Blog