import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//redux
import { useDispatch } from "react-redux";

import DataTable from "react-data-table-component";

//components
import Notification from "../../../Components/Notification/Notification";
import {
  adminCheckIsEditing,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
} from "../../../redux/Admin/adminCartSlice";
import {
  adminAddContainer,
  adminAddContainerId,
  adminAddDeliveryMethod,
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
} from "../../../redux/Admin/adminShipmentStepperSlice";
import { customStyles } from "../../constants/DataTableCustomeStyles";
var deleteKey = require("key-del");

const WaitingShippingShipments = () => {
  const [shippingShipments, setShippingShipments] = useState([]); //second status
  // const [shipmentId, setshipmentId] = useState(initialState);
  //=============================================Shipping Fee Modal========================
  const [ShippingFeeModalShow, setShippingFeeModalShow] = React.useState(false);
  const handleClose = () => setShippingFeeModalShow(false);
  const handleShow = () => setShippingFeeModalShow(true);
  //=============================================Invoice Number Modal========================
  const [shipmentId, setShipmentId] = useState("");
  const [shippingFee, setShippingFee] = useState(0);
  const [InvoiceNumber, setInvoiceNumber] = useState(0);
  const dispatch = useDispatch();

  //===================================Delete Shipment=======================================
  const [DeleteShipmentShow, setDeleteShipmentShow] = useState(false);
  const handleDeleteShipmentClose = () => setDeleteShipmentShow(false);
  const handleDeleteShipmentShow = () => setDeleteShipmentShow(true);

  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  //========================================Handle Freeze Shipment===========================
  const handleFreezeShipment = (e, shipmentId) => {
    setShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };

  //==========================getShippingShipments=========================

  useEffect(() => {
    const getShippingShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=waiting shipping",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setShippingShipments([]);
        console.log(body);
      } else {
        setShippingShipments(body);
      }
    };
    getShippingShipments();
  }, []);
  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${shipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const [error, setError] = useState('');
  //============================Post Shippment shipping Fee===============================
  const postShippingFee = async () => {
    if (!shippingFee || !InvoiceNumber) {
      setError('Please fill in all fields');
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      shippingFee: parseFloat(shippingFee),
      invoiceNumber: parseFloat(InvoiceNumber),
    });
    console.log(raw);
    if (shippingFee > 0 && InvoiceNumber > 0) {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/shipment/shipping-fee/${shipmentId}`,
        requestOptions
      );

      if (!response.ok) {
        alert("Shipping Fee Not Added ");
        console.log(response);
      } else {
        alert("Shipping Fee Has Been Added");
        window.location.reload();
        setError('');
      }
    } else {
      alert("Please Add Both Shipping Fee And Invoice Number");
    }
  };
  //=======================Handle Edit Suggestion=====================
  const navigate = useNavigate();

  const handleEdit = async (suggestion) => {
    const adminUpdatedCartItems = [];
    const adminUpdatedShipmentItems = [];
    var result;
    suggestion.order.items.forEach((item) => {
      item.product.cartQuantity = item.count;
      adminUpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < adminUpdatedCartItems.length; i++) {
      const element = adminUpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",

        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "updatedBy",
        "_id",
      ]);
      adminUpdatedShipmentItems.push(result);
    }

    localStorage.setItem(
      "adminUpdatedCartItems",
      JSON.stringify(adminUpdatedCartItems)
    );
    localStorage.setItem(
      "adminUpdatedShipmentItems",
      JSON.stringify(adminUpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("adminUpdatedSuggestionId", suggestion._id);
    localStorage.setItem("adminUpdatedSuggestionAgentId", suggestion.agent._id);
    dispatch(adminCheckIsEditing(true));
    dispatch(adminUpdateSuggestion(adminUpdatedCartItems));
    dispatch(adminUpdateSuggestionShipment(adminUpdatedShipmentItems));
    dispatch(adminAddExportCountryId(suggestion.exportCountry._id));
    dispatch(adminAddImportCountryId(suggestion.importCountry._id));
    dispatch(adminAddImportStateId(suggestion.state._id));
    dispatch(adminAddDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(adminAddContainerId(suggestion.containerInfo.container._id));
    dispatch(adminAddContainer(suggestion.containerInfo));
    dispatch(shipmentAgentId(suggestion.agent._id));
    navigate("/dashboard/adminCart");
  };
  //============================Delete Shippment===============================

  const DeleteShippment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/admin/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Shippment Not Deleted !!!");
      console.log(response);
    } else {
      alert("Shippment Deleted !!");
      window.location.reload();
    }
  };
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Date",
      selector: (row) => row.order.createdAt.slice(0, 10),
    },
    {
      name: "Container",
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: "Agent",
      selector: (row) => row.agent.name,
    },
    {
      name: "Export Country",
      selector: (row) => row.exportCountry.countryName.en,
    },
    {
      name: "Import Country",
      selector: (row) =>
        row.importCountry.countryName.en + " / " + row.state.stateName.en,
    },
    {
      name: "Note Per Products",
      selector: (row) => <div style={{
        fontSize: "12px",
        display: "flex",
        flexDirection: "column"
      }}>{row?.order?.items?.map((d) => (
        <>{d.note ? <div>   <span>{d.product.subCat.en}</span> : <span>{d.note}</span></div> : ""}</>
      ))}</div>,
      width: "200px"
    },
    {
      name: "Admin Files",
      selector: (row) => (
        <ul className="p-0">
          {row.adminFiles.map((file, index) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file)}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  Suggestion File #{index}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Shipment Files",
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file[1])}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {file[0].slice(0, 16)}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Shipment Fee",
      selector: (row) => (
        <>
          <Button
            variant="warning"
            onClick={() => {
              handleShow(true);
              setShipmentId(row._id);
            }}
          >
            Add Fees
          </Button>

          <Modal show={ShippingFeeModalShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Adding Shipping Fee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Shipping Fee</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="In $ Please :)"
                    autoFocus
                    value={shippingFee}
                    onChange={(e) => setShippingFee(e.target.value)}
                  />
                  <br />
                  <Form.Label>Invoice Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Invoice Number"
                    autoFocus
                    value={InvoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                  <br />
                  <Form.Label>File</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Invoice Number"
                    autoFocus
                    style={{
                      border: "1px solid red"
                    }}
                  />
                </Form.Group>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="warning"
                onClick={() => postShippingFee(row._id)}
              >
                Add Shipping Fee
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: "Freeze",
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          <option>Freeze Shipment</option>
          <option value="true">Freeze</option>
          <option value="false">DeFreeze</option>
        </Form.Select>
      ),
    },
    {
      name: "Edit",
      selector: (row) => (
        <Button variant="warning" onClick={() => handleEdit(row)}>
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteShipmentShow();
              setShipmentId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal show={DeleteShipmentShow} onHide={handleDeleteShipmentClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Shipment
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteShipmentClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => DeleteShippment()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
  ];
  return (
    <section className="waiting-shipping-shipment">
      <Notification />
      <Row>
        <h2>Waiting Shipping Shipments</h2>
        {freezeShipment.freezed ? (
          <Button
            id="freezedShipment"
            variant="dark"
            onClick={() => PostFreezeShipment()}
          >
            Change Shipment Status
          </Button>
        ) : null}
      </Row>
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={shippingShipments}
          highlightOnHover={true}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default WaitingShippingShipments;
