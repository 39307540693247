import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { customStyles } from '../../constants/DataTableCustomeStyles';
import { Button, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import ApiInstances from '../../Api/axiso';
import { useQuery, useQueryClient } from '@tanstack/react-query';


function Prices() {
    const [namePrice, setNamePrice] = useState("")
    const [success, setSuccess] = useState(false)
    const [ProductsPrice, setProductsPrice] = useState([]);
    const [Products, setProducts] = useState([]);
    const [priceId, setPriceId] = useState("")
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const handleDeleteModalShowClose = () => setDeleteModalShow(false);
    const handleDeleteModalShowShow = () => setDeleteModalShow(true);
    const [PriceModelShow, SetPriceModelShow] = useState(false);
    const handlePriceModelShowShow = () => SetPriceModelShow(true);
    const queryClient = useQueryClient()
    const { data, refetch } = useQuery({
        queryKey: ["get-pp"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/prices')
            return data;
        },

    });

    const handlePriceModelClose = () => { SetPriceModelShow(false); setNamePrice(""); setPriceId(null) };
    const PostProductsPrices = async (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");
        const inputFields = document.querySelectorAll("#price");
        inputFields.forEach((field) => {
            field.value = "";
        });
        const prieces = {
            name: namePrice,
            prices: []
        }
        console.log(prieces)
        const url = priceId
            ? `https://www.api.setalkel.co/prices/${priceId}`
            : `https://www.api.setalkel.co/prices`;
        if (priceId) {
            var requestOptions = {
                method: priceId ? "PUT" : "POST",
                headers: myHeaders,
                body: JSON.stringify(prieces),
                redirect: "follow",
            };
            const response = await fetch(
                url,
                requestOptions
            );

            if (!response.ok) {
                // setAddedPrices([]);
                // console.log(body);
                toast.error("There Is Somthing Wrong");
            } else {
                queryClient.refetchQueries(["get-pp"])

                toast.success("Price Ubdated..");
                navigate("/dashboard/crprices3");
                setPriceId(null)
                handlePriceModelClose()
                setNamePrice("")
            }
        } else {



            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(prieces),
                redirect: "follow",
            };
            const response = await fetch(
                `https://www.api.setalkel.co/prices`,
                requestOptions
            );
            const body = await response.json();
            if (!response.ok) {
                // setAddedPrices([]);
                // console.log(body);
                toast.error("There Is Somthing Wrong");
            } else {
                toast.success("Price Added..");
                // navigate("/dashboard/crprices3")
                queryClient.refetchQueries(["get-pp"])
                handlePriceModelClose()
                setSuccess(true)

            }
        }

    };

    useEffect(() => {
        const fetchProduct = async () => {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                `bearer ${localStorage.getItem("admin-token")}`
            );
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: "GET",
                redirect: "follow",
                headers: myHeaders,
            };
            const response = await fetch(
                `https://www.api.setalkel.co/product`,
                requestOptions
            );
            const body = await response.json();
            // console.log(body);
            if (!response.ok) {
            } else {
                setProducts(body?.data)
            }
        };
        fetchProduct()
    }, [])

    const fetchPrice = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: myHeaders,
        };
        const response = await fetch(
            `https://www.api.setalkel.co/prices`,
            requestOptions
        );
        const body = await response.json();
        // console.log(body);
        if (!response.ok) {

            console.log(body);
        } else {
            setProductsPrice(body?.prices)
            // console.log(body)
        }
    }
    useEffect(() => {
        fetchPrice()
    }, [success])
    const onDelete = () => {
        console.log(localStorage.getItem("admin-token"));
        axios
            .delete(`https://www.api.setalkel.co/prices/${priceId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("admin-token"),
                },
            })
            .then((response) => {
                if (response.status !== 204) {
                    // You can handle non-204 responses here if needed
                } else {
                    toast.success("Price deleted");
                    handleDeleteModalShowClose();
                    queryClient.refetchQueries(["get-pp"])
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error("Unauthorized access. Please log in again.");
                } else {
                    toast.error("An error occurred. Please try again later.");
                }
                console.log(error);
            });
    };

    const handleInfoInputChange = (e) => {
        const { name, value } = e.target;
        setNamePrice(value);
    };
    const navigate = useNavigate()
    useEffect(() => {
        const getPriceById = async () => {
            try {
                var myHeaders = new Headers();
                myHeaders.append(
                    "Authorization",
                    `bearer ${localStorage.getItem("admin-token")}`
                );

                var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(
                    `https://www.api.setalkel.co/prices/${priceId}`,
                    requestOptions
                );
                const body = await response.json();
                if (!response.ok) {
                    console.log(body);
                } else {
                    setNamePrice(body.name)
                }
            } catch (error) {
                console.log(error);
            }
        };
        priceId && getPriceById();

    }, [priceId]);
    const columns = [
        {
            name: "#",
            selector: (row, index) => <span>{index + 1}</span>,

        },

        { name: "Price Name", selector: (row) => <span>{row.name}</span> },

        {
            name: "Delete",
            selector: (row) => (
                <Button
                    variant="danger"
                    onClick={() => {
                        handleDeleteModalShowShow();
                        setPriceId(row._id);
                    }}
                >
                    Delete
                </Button>
            ),
        },
        {
            name: "Ubdate",
            selector: (row) => (
                <Button
                    variant="warning"
                    onClick={() => { handlePriceModelShowShow(); setPriceId(row._id); }}
                >
                    Update
                </Button>
            ),
        },


    ];
    return (
        <>  <div style={{
            display: "flex",
            alignItems: "center"
        }}>

            <Button
                variant="warning"
                onClick={() => { handlePriceModelShowShow() }}
            >
                Create Prices
            </Button>
            <Button variant="warning" id="verify-btn">
                Export Excel File
            </Button>
            <Button
                variant="danger"

            >
                Delete All
            </Button>
        </div>

            <Modal
                show={PriceModelShow}
                onHide={handlePriceModelClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create Prices
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="products-form">
                        <Form.Group>
                            <Form.Label htmlFor="ProductNameInEn">
                                Name of Price
                            </Form.Label>
                            <Form.Control
                                name="name"
                                label="valuePrice"
                                value={namePrice}
                                onChange={handleInfoInputChange}
                                placeholder="price"
                            />
                        </Form.Group>
                        <div className='d-flex'>
                            <Button type='button' variant="danger" onClick={handlePriceModelClose}>
                                Close
                            </Button>
                            <Button type='submit' variant="warning" onClick={PostProductsPrices}>
                                {priceId ? " Update Price" : "   Add Price"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <DataTable
                fixedHeader
                pagination
                responsive
                subHeaderWrap
                highlightOnHover
                columns={columns}
                customStyles={customStyles}
                data={data?.prices}
            />
            <Modal show={DeleteModalShow} onHide={handleDeleteModalShowClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are You Sure You Want To Delete This Price
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalShowClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onDelete()}>
                        Delete Price
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Prices
