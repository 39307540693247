import React from 'react'
import { Button, Row, Table } from 'react-bootstrap'

const Agent = () => {
    return (
        <Row style={{
            background: "white",
            textAlign:"center",
            padding:"10px",
            display:"flex",
            flexDirection:"column",
            gap:"10px"
       }}>
            <h1>Sessions Agent</h1>
            <div className='d-flex gap-2 align-items-center'>
               
               <Button variant="danger" id="verify-btn" >
                   Export Excel File for Pallet
               </Button>
               <Button variant="danger" id="verify-btn">
                   Import Excel
               </Button>
               <Button variant="danger" id="verify-btn">
                   Update
               </Button>
               <Button variant="danger" id="verify-btn">
                   Delete
               </Button>
               <Button variant="danger" id="verify-btn">
                   Show/Hide
               </Button>
               <Button variant="danger" id="verify-btn">
                   Close Sessions
               </Button>
           </div>
            <Table className='table-drag ' style={{
                background: "white",
                textAlign:"center"
            }}>
                <thead style={{
                     background: "white"
                }}>
                    <tr>
                        <th style={{ width: "100px" }}>Name</th>
                        <th style={{ width: "100px" }}>Start Time</th>
                        <th style={{ width: "100px" }}>End  Time</th>
                        <th style={{ width: "100px" }}>IP</th>
                        <th style={{ width: "100px" }}>Close Sessions</th>
                    </tr>
                </thead>
                
            </Table>
            <div style={{
                    textAlign:"center",
                    display:"flex",
                    justifyContent:"center",
                    width:"100%"
                }}>
                    <h3>  No Data Yet</h3>
                </div>
        </Row>
    )
}

export default Agent