import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const PromotionalMaterials = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <h1>Promotional Materials</h1>

            <div className='d-flex gap-2 align-items-center'>
                <Button onClick={() => {
                    navigate("/dashboard/add-promotionalmaterials")
                }} variant="warning" id="verify-btn">
                    Add PromotionalMaterials
                </Button>
                <Button variant="danger" id="verify-btn" >
                    Export Excel File for Pallet
                </Button>
                <Button variant="danger" id="verify-btn">
                    Import Excel
                </Button>
                <Button variant="danger" id="verify-btn">
                    Update
                </Button>
                <Button variant="danger" id="verify-btn">
                    Delete
                </Button>
                <Button variant="danger" id="verify-btn">
                    Show/Hide
                </Button>
            </div>

        </Row>
    )
}

export default PromotionalMaterials