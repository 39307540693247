import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//css
import "./Banner.css";

import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useRef } from "react";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Banner = () => {
  const [BannerGallery, setBannerGallery] = useState([]);
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  // const fileInputRef = useRef(null);
  const [deletedImageId, setDeletedImageId] = useState("");
  //==================================Delete Img Modal States================================
  const [DeleteImgShow, setDeleteImgShow] = useState(false);
  const handleClose = () => setDeleteImgShow(false);
  const handleShow = () => setDeleteImgShow(true);
  //==================================Delete Images Modal States================================
  const [DeleteAllBannerModalShow, setDeleteAllBannerModalShow] =
    useState(false);

  const handleDeleteAllModalClose = () => setDeleteAllBannerModalShow(false);
  const handleDeleteAllModalShow = () => setDeleteAllBannerModalShow(true);

  //==================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    const newImages = Array.from(e.target.files);

    setImgGallery(prevState => {
      const allImages = [...prevState.ImgGallery, ...newImages];

      return {
        ImgGallery: allImages
      };
    });
  };
  //=========================Handle Delete Image==================
  const handleDeleteImage = (image) => {
    const id = image.slice(7);
    setDeletedImageId(id);
  };
  //=========================get Banner Gallert=======================

  useEffect(() => {
    const getBannerImages = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/banner/images",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setBannerGallery([]);
        console.log(body);
      } else {
        setBannerGallery(body);
      }
    };
    getBannerImages();
  }, []);
  //=====================================Post Kitchen Gallery====================
  const PostBannerGallery = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/banner/images`,
        requestOptions
      );
      const body = await ImgResponse.json();
      if (!ImgResponse.ok) {
        alert("Images Not Added !!!!");
        console.log(body);
      } else {
        alert("Images Has Beend Added Successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //=======================Delete Image from Banner=================
  const deleteImageFromBanner = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/banner/${deletedImageId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Not Deleted");
      console.log(response);
    } else {
      alert("Image Deleted");
      window.location.reload();
    }
  };
  const getFileImg = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //=============================Delete All Banner==================
  const DeleteAllBanner = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/banner/",
      requestOptions
    );
    if (!response.ok) {
      alert("Not Deleted");
      console.log(response);
    } else {
      alert("Banner Deleted");
      window.location.reload();
    }
  };
  const navigate = useNavigate()
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="d-flex position-relative">
          <img
            src={"https://www.api.setalkel.co" + row}
            alt="productImg"
            style={{
              height: "200px",
              width: "200px",
            }}
          />
          <FaDownload onClick={() => getFileImg(row)} style={{
            color: "red",
            fontSize: "15px",
            position: "absolute",
            top: "0",
            right: "0"

          }} />
        </div>
      ),
    },
    {
      name: "Show/Hide Banner",
      selector: (row) => (
        <Form.Select style={{
          border: "1px solid red"
        }}>
          <option>Choose State</option>
          <option value="false">Show</option>
          <option value="true">Hide</option>
        </Form.Select>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteImage(row);
              handleShow();
            }}
          >
            Delete
          </Button>
          <Modal
            show={DeleteImgShow}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are You Sure You Want To Delete Image ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => deleteImageFromBanner()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="cms-banner">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1>Banner Images</h1>
          </div>
        </div>
      </Row>
      <Row>
        <div style={{
          display: "flex",
          width: "100%",
          gap: "10px"
        }}>
          <div className="cms-banner-images">
            <h2>Add Images</h2>
            <Form>
              <Form.Group>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <Form.Control
                    type="file"
                    name="images"
                    multiple
                    id="productImg"
                    onChange={handleGalleryChange}
                  />
                  <div style={{ display: "flex", gap: "5px" }}>
                    {ImgGallery.ImgGallery.map((image, index) => (
                      <div key={index} style={{ position: "relative", display: "inline-block" }}>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover"
                          }}
                          src={URL.createObjectURL(image)}
                          alt={`Gallery Img ${index + 1}`}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgGallery(prevState => ({
                              ImgGallery: prevState.ImgGallery.filter((_, indexIM) => indexIM !== index)
                            }));
                          }}
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-11px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Group>
              <div className="d-flex gap-2">
                <Button variant="warning" onClick={PostBannerGallery}>
                  Add Images
                </Button>
                <Button variant="danger" onClick={handleDeleteAllModalShow}>
                  Delete All Banner
                </Button>
              </div>

            </Form>
          </div>
          <div className="cms-banner-images">
            <h2>Add Videos</h2>
            <Form>
              <Form.Group>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <Form.Control
                    type="file"
                    name="images"
                    multiple
                    id="productImg"
                    style={{ border: "1px solid red" }}
                  />

                </div>
              </Form.Group>
              <div className="d-flex gap-2">
                <Button variant="warning" onClick={PostBannerGallery}>
                  Add Videos
                </Button>

              </div>

            </Form>
          </div>
          <Button style={{
            height: "fit-content"
          }} variant="warning" onClick={() => {
            navigate("/dashboard/add-banner")
          }}>
            Add Banner Description
          </Button>
          
        </div>
      </Row>
      <hr />
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={BannerGallery.images}
          customStyles={customStyles}
        />
      </Row>

      <Modal
        show={DeleteAllBannerModalShow}
        onHide={handleDeleteAllModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are You Sure You Want To Delete All Banner Images
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteAllModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={DeleteAllBanner}>
            Delete All Banner Images
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Banner;
