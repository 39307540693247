import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
} from "semantic-ui-react";
//css
import "./CRAboutUs.css";
//components
import PreLoader from "../../../Components/PreLoader/PreLoader";
import ReactPlayer from "react-player";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import { FaDownload, FaTrash, FaTrashAlt } from "react-icons/fa";
const CRAboutUS = () => {
  const [aboutUsData, setAboutUsData] = useState({});
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const [loading, setLoading] = useState(false);
  //====================================Delete Image============================
  const [DeleteImageModalShow, setDeleteImageModalShow] = useState(false);
  const handleDeleteImageModalClose = () => setDeleteImageModalShow(false);
  const handleDeleteImageModalShow = () => setDeleteImageModalShow(true);
  const [DeleteImageId, setDeleteImageId] = useState("");

  //====================================Delete Image============================

  //==========================Handle Img Id==================
  const handleImage = (item) => {
    setDeleteImageId(item.slice(7));
  };
  //=======================Input Handeling===============

  //====================Fetch About Us========================
  const fetchAboutUs = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/aboutUs",
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      // console.log(body);
      setLoading(true);
      setAboutUsData({});
    } else {
      setLoading(false);
      setAboutUsData(body);
      // console.log(body)
    }
  };
  useEffect(() => {
    fetchAboutUs();
  }, []);
  //====================Post About Us========================

  //==============================================Delete About Us Image===============================
  const deleteAboutUsImage = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/aboutUs/image/${DeleteImageId}`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Image Not Deleted");
      // console.log(response);
    } else {
      toast.success("Image Deleted");
    }
  };
  const navigate = useNavigate()
  //==============================================Delete About Us Video===============================
  const deleteAboutUsVideo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/aboutUs/video`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Video Not Deleted");
      console.log(response);
    } else {
      toast.success("Video Deleted");

    }
  };
  if (loading) {
    return <PreLoader />;
  }

  const columns1 = [
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row}`}
            alt=""
            width="70px"
            height="70px"
          />
        </span>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteImageModalShow();
              handleImage(row);
            }}
          >
            Delete
          </Button>

        </span>
      ),
    },
  ];

  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForAboutUs()
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="cms-aboutUs">
      <Row>
        <div className="crud-title">
          <h2>About Us</h2>
          <span style={{ color: "red" }}>
            Note : Every Time We Post New "About Us Desc" It Update The Orginal
            One
          </span>
        </div>
      </Row>
      <div className="btns">
        <Button variant="warning" onClick={() => navigate("add-about")}>
          AddAboutUs
        </Button>
        {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
          Export Excel  About Us
        </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
          Download Excel File
        </Button>}
        <Button variant="danger" id="verify-btn" >
          Import Excel
        </Button>
        <Button variant="danger" id="verify-btn" >
          Refresh
        </Button>
       
      </div>
      <div className="crud-about">
        <div className="video">
          <h4>Video</h4>
          <ReactPlayer
            controls
            loop={true}
            volume="1"
            url={`https://www.api.setalkel.co${aboutUsData.file}`}
            style={{
              width: "100%",
              height: "50%",
            }}
          />
          <div style={{
            display: "flex"
          }}>
            <Button
              variant="danger"
              onClick={deleteAboutUsVideo}
            >
              Delete Video
            </Button>
            <Button
              variant="warning"
            // onClick={deleteAboutUsVideo}
            >
              Update Video
            </Button>
          </div>
        </div>
        <div>
        </div>
      </div>
      <hr />
      <h2>Dicsription</h2>
      <div style={{
        display: "flex",
        fontSize: "13px",
        gap: "5px"
      }}>
        <p className="text-black">English:</p> <p>{aboutUsData?.desc?.en}</p>
      </div>
      <div style={{
        display: "flex",
        fontSize: "13px",
        gap: "5px"
      }}>
        <p className="text-black">Arabic:</p> <p>{aboutUsData?.desc?.ar}</p>
      </div>
      <div style={{
        display: "flex",
        fontSize: "13px",
        gap: "5px"
      }}>
        <p className="text-black">German:</p> <p>{aboutUsData?.desc?.de}</p>
      </div>



      <Row>
        <hr />
        <h4>Gallery</h4>
        <div className="card-grid">
          {aboutUsData?.gallery?.map((item) => (
            <div className="card-item-about">
              <img src={`https://www.api.setalkel.co${item}`} style={{ width: "150px", height: "120px" }} />
              <FaTrashAlt color="red" style={{
                cursor: "pointer",
                fontSize: "18px"

              }} onClick={() => {
                handleDeleteImageModalShow();
                handleImage(item);
              }} />
              <FaDownload onClick={() => getFile(item)} style={{
                color: "red",
                fontSize: "15px",
                position: "absolute",
                top: "0",
                right: "15px"

              }} />

            </div>
          ))}
        </div>


        <Modal
          show={DeleteImageModalShow}
          onHide={handleDeleteImageModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Are You Sure You Want To Delete This Image
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="dark" onClick={handleDeleteImageModalClose}>
              Close
            </Button>
            <Button variant="danger" onClick={deleteAboutUsImage}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </section>
  );
};

export default CRAboutUS;
