import React, { useState } from 'react';
import { Table, Form } from 'react-bootstrap';

const icons = [
    'State', 'Account', 'Rating',
    'Agent', 'AboutUs', 'Product', 'ProductCat', 'Contact Us', 'Bites', 'Partners', 'ProductPrice', 'Delivery-method',
    'Activity', 'Offer', 'Containers', 'Countries', 'max-weight-container', 'Kitchen',
    'Admins', 'App Version', 'Pallet', 'Advertisements', 'Promotional Materials', 'Sessions', 'Shipment'
];

const Roles = [
    "c-productCat", "u-productCat", "d-productCat", "c-product", "u-product", "d-product",
    "c-admin", "r-admin", "u-admin", "d-admin", "u-admin-with-roles", "c-agent", "r-agent",
    "u-agent", "d-agent", "c-productPrice", "r-productPrice", "u-productPrice", "d-productPrice",
    "c-container", "r-container", "u-container", "d-container", "c-shipment", "r-shipment",
    "u-shipment", "d-shipment", "upload-shipment-file", "r-contactUs", "d-contactUs", "c-kitchen",
    "u-kitchen", "d-kitchen", "c-activity", "u-activity", "d-activity", "aboutUs", "partner",
    "c-offer", "u-offer", "d-offer", "uploads", "c-country", "u-country", "d-country", "r-country",
    "c-state", "u-state", "d-state", "r-state", "c-max-weight-container", "u-max-weight-container",
    "d-max-weight-container", "r-max-weight-container", "c-delivery-method", "r-delivery-method",
    "u-delivery-method", "d-delivery-method", "c-notification", "r-notification", "d-notification",
    "r-rating", "d-rating", "u-rating", "show-hidden-rating", "c-account", "r-account",
    "d-account", "super admin", "r-product"
];

const mapPermissionsToRoles = (icon) => {
    const lowerCaseIcon = icon.toLowerCase().replace(/\s/g, '');
    const permissions = {
        name: icon,
        create: false,
        read: false,
        update: false,
        delete: false,
        show: false, // Leave as default
        approveCreate: false, // Leave as default
        approveUpdate: false, // Leave as default
        approveDelete: false // Leave as default
    };
    return permissions;
};

const initialPermissions = icons.map(mapPermissionsToRoles);

const PermissionsPage = () => {

    const [permissions, setPermissions] = useState(initialPermissions);
    const [selectedPermissions, setSelectedPermissions] = useState([]); // Array to store selected permissions
    console.log(selectedPermissions)
    const handleCheckboxChange = (iconName, permission) => {
        setPermissions(prevPermissions =>
            prevPermissions.map(icon =>
                icon.name === iconName
                    ? { ...icon, [permission]: !icon[permission] }
                    : icon
            )
        );

        // Map permission names to their first letters
        const permissionShortcuts = {
            create: 'c',
            read: 'r',
            update: 'u',
            delete: 'd',
            show: 's',
            approveCreate: 'ac',
            approveUpdate: 'au',
            approveDelete: 'ad'
        };

        // Construct permission string with the first letter of each permission
        const permissionString = `${permissionShortcuts[permission]}-${iconName.toLowerCase()}`;

        // Update selectedPermissions array
        setSelectedPermissions(prevSelected => {
            if (prevSelected.includes(permissionString)) {
                return prevSelected.filter(p => p !== permissionString);
            } else {
                return [...prevSelected, permissionString];
            }
        });
    };


    return (
        <div className="container mt-4">
            <h3 className="text-center mb-4">Permissions</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Icon Name</th>
                        <th>Read</th>
                        <th>Show</th>
                        <th>Create</th>
                        <th>Update</th>
                        <th>Approve for Update</th>
                        <th>Approve for Create</th>
                        <th>Delete</th>
                        <th>Approve for Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {permissions.map(icon => (
                        <tr key={icon.name}>
                            <td>{icon.name}</td>
                            {['read', 'show', 'create', 'update', 'approveUpdate', 'approveCreate', 'delete', 'approveDelete'].map(permission => (
                                <td key={permission}>
                                    <Form.Check
                                        type="checkbox"
                                        checked={icon[permission]}
                                        onChange={() => handleCheckboxChange(icon.name, permission)}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default PermissionsPage;
