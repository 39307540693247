import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";

//css
import "./CRImportCountry.css";

import toast from "react-hot-toast"
import { useNavigate } from 'react-router-dom';
const AddImportCountries = () => {

  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryNames, setCountryNames] = useState({
    en: "", //required
    ar: "", //required
    fr: "",
    ru: "",
    de: "",
    cn: "",
    tur: "",
    ir: "",
    dk: "",
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const fileInputRef = useRef(null);
  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCountryNames({
      ...countryNames,
      [name]: value,
    });
  };
  useEffect(() => {
    const getCountriesCodes = async () => {
      const response = await fetch("https://restcountries.com/v2/all");
      const body = await response.json();
      if (!response.ok) {
        setCountries([]);
        console.log(body);
      } else {
        setCountries(body);
      }
    };
    getCountriesCodes();
  }, []);
  const addImportCountryImage = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var formdata = new FormData();
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/image/${id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Image Has Not Been Added !!!!!!!!!!");
    } else {
      alert("Image Has Been Added :) :):):):)");
    }
  };
  const navigate = useNavigate()
  //======================Add Import Country Info================
  const addImportCountryInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    let NewCountryNames = countryNames;
    Object.keys(NewCountryNames).forEach((key) => {
      if (NewCountryNames[key] === "") {
        delete NewCountryNames[key];
      }
    });
    var raw = JSON.stringify({
      countryCode: countryCode,
      countryName: NewCountryNames,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/import-country/create/country",
      requestOptions
    );
    const body = await response.json();
    if (response.status === 409) {
      toast.error("Previously Added Country");
      return
    }
    if (!response.ok) {
      toast.error("Country Not Added !!!!!!!!");
      console.log(response);
      
    } else {
      if (Imgfile.Imgfile) {
        await addImportCountryImage(body._id);
      }
      toast.success("Country Has Been Added");
      setCountryNames({
        en: "", //required
        ar: "", //required
        fr: "",
        ru: "",
        de: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
      });
      navigate("/dashboard/crimportcountries")
    }
  };
  return (
    <div className="create-import-country">
      <Form>
        <Form.Label htmlFor="CountryNameInEn" style={{ color: "red" }}>
          Arabic & English R Required
        </Form.Label>
        <div className="country-langs">
          <FormGroup>
            <FormLabel>
              Country Code
              <select
                id="category-list"
                name="countryCode"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option>Please Choose A Country Code</option>
                {countries.map((country, index) => {
                  return (
                    <option value={country.alpha2Code} key={index}>
                      {`${country.alpha2Code + "===>" + country.name}`}
                    </option>
                  );
                })}
                <option value={"EU"}>{`EU  ===> European Union`}</option>
              </select>
            </FormLabel>
          </FormGroup>

          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In English /
              <span style={{ color: "red" }}>Required</span>
            </Form.Label>
            <Form.Control
              name="en"
              label="category"
              value={countryNames.en}
              onChange={handleInputChange}
              placeholder="name in en"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In Arabic /
              <span style={{ color: "red" }}>Required</span>
            </Form.Label>

            <Form.Control
              name="ar"
              label="category"
              value={countryNames.ar}
              onChange={handleInputChange}
              placeholder="name in ar"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In French
            </Form.Label>

            <Form.Control
              name="fr"
              label="category"
              value={countryNames.fr}
              onChange={handleInputChange}
              placeholder="name in fr"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In Russian
            </Form.Label>

            <Form.Control
              name="ru"
              label="category"
              value={countryNames.ru}
              onChange={handleInputChange}
              placeholder="name in ru"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In German
            </Form.Label>

            <Form.Control
              name="de"
              label="category"
              value={countryNames.de}
              onChange={handleInputChange}
              placeholder="name in de"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In China
            </Form.Label>

            <Form.Control
              name="cn"
              label="category"
              value={countryNames.cn}
              onChange={handleInputChange}
              placeholder="name in cn"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In Turkish
            </Form.Label>
            <Form.Control
              name="tur"
              label="category"
              value={countryNames.tur}
              onChange={handleInputChange}
              placeholder="name in tur"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In Persian
            </Form.Label>
            <Form.Control
              name="ir"
              label="category"
              value={countryNames.ir}
              onChange={handleInputChange}
              placeholder="name in ir"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="CountryNameInEn">
              Country Name In Danish
            </Form.Label>
            <Form.Control
              name="dk"
              label="category"
              value={countryNames.dk}
              onChange={handleInputChange}
              placeholder="name in Danish"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="CountryImg">Country Image</Form.Label>
            <div className="d-flex flex-column ">
              <Form.Control
                type="file"
                name="image"
                id="productImg"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{
                  height: "fit-content"
                }}
              />
              {Imgfile.Imgfile && (
                <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={URL.createObjectURL(Imgfile.Imgfile)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setImgfile({
                        Imgfile: null,
                      });
                      if (fileInputRef.current) {
                        fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                      }
                    }}
                    style={{
                      position: "absolute",
                      top: "-18px",
                      right: "5px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
          </Form.Group>
        </div>


        <Button
          variant="warning"
          onClick={addImportCountryInfo}
          style={{ marginTop: "50px" }}
        >
          Add Import Country
        </Button>

      </Form>
    </div>
  )
}

export default AddImportCountries