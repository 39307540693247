import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Advertisements = () => {
    const navigate = useNavigate()
    return (
        <Row style={{
            background: "white",
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        }}>
            <h1>Advertisements</h1>
            <Row>
                <div className='d-flex gap-2 align-items-center'>
                    <Button style={{
                        width: "fit-content"
                    }} variant="warning" id="verify-btn" onClick={() => navigate("/dashboard/add-advertisements")}>
                        Add Advertisements
                    </Button>
                    <Button variant="danger" id="verify-btn" >
                        Export Excel File for Pallet
                    </Button>
                    <Button variant="danger" id="verify-btn">
                        Import Excel
                    </Button>
                    <Button variant="danger" id="verify-btn">
                        Update
                    </Button>
                    <Button variant="danger" id="verify-btn">
                        Delete
                    </Button>
                    <Button variant="danger" id="verify-btn">
                        Show/Hide
                    </Button>
                </div>
            </Row>
        </Row>
    )
}

export default Advertisements