import React from 'react'

import { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap'
const AddPallet = () => {
    return (
        <section className="cms-create-products">
            <Row>
                <div className="crud-products">
                    <div className="crud-title">
                        <h1 >Add Pallet </h1>
                    </div>
                </div>
            </Row>
            <Form onSubmit={() => { }} className="product-info">
                <Form.Group>
                    <Form.Label >
                        Weight
                    </Form.Label>
                    <Form.Control
                        style={{
                            border: "1px solid red"
                        }}
                        name="en"
                        label="en"

                        placeholder="Weight"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label >
                        Weight unit
                    </Form.Label>
                    <Form.Control
                        style={{
                            border: "1px solid red"
                        }}
                        name="en"
                        label="en"
                        placeholder="Weight unit"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label >
                        Length
                    </Form.Label>
                    <Form.Control
                        style={{
                            border: "1px solid red"
                        }}
                        name="en"
                        label="en"

                        placeholder="Length"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label >
                        Width
                    </Form.Label>
                    <Form.Control
                        style={{
                            border: "1px solid red"
                        }}
                        name="en"
                        label="en"

                        placeholder="Width"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label >
                        Height
                    </Form.Label>
                    <Form.Control
                        style={{
                            border: "1px solid red"
                        }}
                        name="en"
                        label="en"

                        placeholder="Height"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="ProductImg">Pallet Image</Form.Label>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <Form.Control
                            style={{
                                border: "1px solid red"
                            }}
                            type="file"
                            name="image"
                            id="productImg"

                        />

                    </div>
                </Form.Group>
            </Form>
            <Button onClick={() => {
                // navigate("/dashboard/add-pallet")
            }} variant="warning" id="verify-btn">
                Add Pallet
            </Button>
        </section>

    )
}

export default AddPallet