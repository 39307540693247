import React, { useEffect, useRef, useState } from "react";
//css
import "./ShipmentProductCard.css";
//bootstrap
import { Col, Row } from "react-bootstrap";
//icons
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Buy } from "react-iconly";
//images
import TrashImg from "../../../assets/Payments/trash.png";
//redux
import {
  addAmountToCart,
  addToCart,
  decreaseCart,
  removeFromCart,
  // removeFromCart,
} from "../../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
//translation
import { useTranslation } from "react-i18next";
const ShipmentProductCard = ({ product }) => {
  const QuantityRef = useRef();
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  //===============================Add To Cart========================
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  //======================Decrease Cart Amount========================

  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  //=======================add Amount Handle============================

  const handleAmountChange = (e, cartItem) => {
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== null &&
      e.target.value > 0
    ) {
      dispatch(
        addAmountToCart({
          amount: parseInt(e.target.value),
          cartItem: cartItem,
        })
      );
    } else {
      QuantityRef.current.value = "";
    }
  };
  //===================================removeItemFromCart=========================
  const removeItemFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
  };
  //==========================stockOffProduct===============================

  const stockOffProduct = () => {
    alert(
      "This Product Is Out Of Stock Please Contact Us On The Contact Page To Discuss About It "
    );
  };
  // console.log(product);
  return (
    <article className="shipment-product-card" key={product._id}>
      <div className="shipment-product-card-h2">
        {cartItems.map((item) =>
          item._id === product._id && item.cartQuantity > 0 ? (
            <img
              src={TrashImg}
              alt="TrashImg"
              onClick={() => removeItemFromCart(item)}
              id="deleteImg"
            />
          ) : null
        )}
        <img
          src={"https://www.api.setalkel.co" + product.img}
          alt="productImg"
        />
      </div>
      <div className="shipment-product-card-h1">
        <div className="shipment-product-card-title">
          <div className="shipment-product-card-title-info">
            <h5>
              {product.subCat[docLang]
                ? product.subCat[docLang]
                : product.subCat.en}
            </h5>
            <p>
              {product.subCat[docLang]
                ? product.subCat[docLang]
                : product.subCat.en}
              <span className="mx-2">
                {product?.peiceQty?.usualWeight}
              </span>
            </p>
          </div>
          <div className="shipment-product-card-title-btn">
            {product.stockOff === true ? (
              <button
                className="stockOff-btn"
                onClick={() => stockOffProduct()}
              >
                {t(
                  `Components.ShipmentComponents.ShipmentProductCard.StockOff`
                )}
              </button>
            ) : (
              <>
                {product.productPrice > 0 && (
                  <button onClick={() => handleAddToCart(product)}>
                    <Buy />
                    {t(
                      `Components.ShipmentComponents.ShipmentProductCard.AddToCart`
                    )}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <hr id="product-card-hr" />
        <div className="shipment-product-card-details">
          <div className="info-data">
            <div>
              <Row>
                <Col>
                  {t(
                    `Components.ShipmentComponents.ShipmentProductCard.PieceWeight`
                  )}
                </Col>
                <Col>{`${product.peiceQty.usualWeight} ${product.peiceQty.unit}`}</Col>
              </Row>
              <Row>
                <Col>
                  {t(
                    `Components.ShipmentComponents.ShipmentProductCard.GrossWeight`
                  )}
                </Col>
                <Col>{`${product.packageGrossWeight
                  ? product.packageGrossWeight
                  : (product.packageGrossWeight = 0)
                  } KG `}</Col>
              </Row>
              <Row>
                <Col>
                  {t(
                    `Components.ShipmentComponents.ShipmentProductCard.BoxVolume`
                  )}
                </Col>
                <Col>
                  {product.volume ? product.volume : (product.volume = 0)}
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  {t(
                    `Components.ShipmentComponents.ShipmentProductCard.BoxPrice`
                  )}
                </Col>
                <Col>
                  {product.productPrice
                    ? product.productPrice.toFixed(2)
                    : (product.productPrice = 0)}
                </Col>
              </Row>
              <Row>
                <Col>
                  {t(
                    `Components.ShipmentComponents.ShipmentProductCard.CountInBox`
                  )}
                </Col>
                <Col>
                  {product.countInBox
                    ? product.countInBox
                    : (product.countInBox = 0)}
                </Col>
              </Row>
            </div>
          </div>

          <div className="shipment-product-card-qty">
            <p>
              {t(
                `Components.ShipmentComponents.ShipmentProductCard.PackageQuantity`
              )}
            </p>
            <div className="btn-group">
              {product.productPrice > 0 ? (
                <>
                  <button onClick={() => handleAddToCart(product)}>
                    <AiOutlinePlus />
                  </button>
                  {cartItems.map((item) => {
                    return item._id === product._id ? (
                      <input
                        ref={QuantityRef}
                        type="text"
                        min={0}
                        pattern="\d*"
                        maxlength="4"
                        placeholder="QTY"
                        id={product._id}
                        value={item.cartQuantity}
                        onChange={(e) => handleAmountChange(e, product)}
                      />
                    ) : null;
                  })}

                  <button onClick={() => handleDecreaseCart(product)}>
                    <AiOutlineMinus />
                  </button>
                </>
              ) : (
                "No Price"
              )}
            </div>
          </div>
        </div>
      </div>


    </article>
  );
};

export default ShipmentProductCard;
