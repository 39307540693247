import React, { useEffect, useState } from "react";
//css
import "./AdminShipmentTotals.css";
//images
import Volume from "../../../../../assets/Payments/volume.png";
import Weight from "../../../../../assets/Payments/weight.png";
import Price from "../../../../../assets/Payments/price.png";
//redux
import { useDispatch, useSelector } from "react-redux";
import { adminShipmentInRange } from "../../../../../redux/Admin/adminCartSlice";
const AdminShipmentTotals = () => {
  const adminCart = useSelector((state) => state.adminCart);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [totalWeightWarn, setTotalWeightWarn] = useState(false);
  const [totalVolumeWarn, setTotalVolumeWarn] = useState(false);
  const choosenContainer = state.shipmentStepper.container;
  const totalVolume = state.adminCart.adminCartTotalVolume;
  const totalWeight = state.adminCart.adminCartTotalWeight;
  // const adminOverload = useSelector((state) => state.adminCart.adminOverload);


  useEffect(() => {
    if (
      choosenContainer?.container?.maxVolume !== null &&
      choosenContainer.maxWeightInState !== null &&
      choosenContainer.minWeightInState !== null &&
      !isNaN(choosenContainer?.container?.maxVolume) &&
      !isNaN(choosenContainer.maxWeightInState) &&
      !isNaN(choosenContainer.minWeightInState)
    ) {
      if (
        totalVolume <= choosenContainer?.container?.maxVolume &&
        totalWeight <= choosenContainer.maxWeightInState &&
        totalWeight >= choosenContainer.minWeightInState
      ) {
        setTotalWeightWarn(false);
        setTotalVolumeWarn(false);
        dispatch(adminShipmentInRange(true));
      } else {
        if (
          totalVolume > choosenContainer?.container?.maxVolume &&
          (totalWeight > choosenContainer.maxWeightInState ||
            totalWeight < choosenContainer.minWeightInState)
        ) {
          setTotalVolumeWarn(true);
          setTotalWeightWarn(true);
          dispatch(adminShipmentInRange(false));
          return;
        } else if (totalVolume > choosenContainer?.container?.maxVolume) {
          setTotalVolumeWarn(true);
          setTotalWeightWarn(false);
          dispatch(adminShipmentInRange(false));
          return;
        } else if (totalWeight > choosenContainer.maxWeightInState) {
          setTotalWeightWarn(true);
          setTotalVolumeWarn(false);
          dispatch(adminShipmentInRange(false));
          return;
        } else if (totalWeight < choosenContainer.minWeightInState) {
          setTotalWeightWarn(true);
          setTotalVolumeWarn(false);
          dispatch(adminShipmentInRange(false));
          return;
        } else {
          console.log("");
        }
      }
    }
  }, [
    choosenContainer?.container?.maxVolume,
    choosenContainer?.maxWeightInState,
    choosenContainer?.minWeightInState,
    dispatch,
    totalVolume,
    totalWeight,
  ]);
  return (
    <article className="admin-cart-totals">
      <div
        className="admin-cart-totals-overload-div"
      
      >
        <div>
          <span>Total Volume</span>
          <img src={Volume} alt="Volume" />
        </div>
        <span>{adminCart.adminCartTotalVolume} Kg</span>
      </div>

      <div
        className="admin-cart-totals-overload-div"
        style={{
          background: totalWeightWarn ? "var(--color-warning)" : "",
        }}
      >
        <div>
          <span>Total Weight</span>
          <img src={Weight} alt="wight" />
        </div>
        <span>{adminCart.adminCartTotalWeight} Kg</span>
      </div>
      <div className="admin-cart-totals-overload-div">
        <div>
          <span>Total Price</span>
          <img src={Price} alt="Price" />
        </div>
        <span>{adminCart.adminCartTotalAmount}$</span>
      </div>
    </article>
  );
};

export default AdminShipmentTotals;
