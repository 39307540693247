import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Pallet = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <div className='d-flex'>
                <Button variant="danger" id="verify-btn" >
                    Export Excel File for Pallet
                </Button>
                <Button variant="danger" id="verify-btn">
                    Import Excel
                </Button>
                <Button onClick={() => {
                    navigate("/dashboard/add-pallet")
                }} variant="warning" id="verify-btn">
                    Add Pallet
                </Button>
                <Button variant="danger" id="verify-btn">
                    Update
                </Button>
                <Button variant="danger" id="verify-btn">
                    Delete
                </Button>
                <Button variant="danger" id="verify-btn">
                    Show/Hide
                </Button>
            </div>
        </Row>
    )
}

export default Pallet