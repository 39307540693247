import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

const AddEvents = () => {
    return (
        <Row>
            <h4>Add Events</h4>
            <Form className="activities-form">
                <Row>
                    <Col>
                        <div className="activity-langs">
                            {/* =====================================Start Events Name=========================================== */}
                            <h4>Events Name </h4>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In English
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="en"
                                    label="Our-Events"
                                    placeholder=" Events Name In English"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In Arabic
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ar"
                                    label="Our-Events"

                                    placeholder="Events Name In Arabic"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In French
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="fr"
                                    label="Our-Events"
                                    placeholder=" Events Name In French"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In Russian
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ru"
                                    label="Our-Events"
                                    placeholder=" Events Name In Russian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In German
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="de"
                                    label="Our-Events"
                                    placeholder="Events Name In Germany"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In China
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="cn"
                                    label="Our-Events"
                                    placeholder="Events Name In China"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In Turkish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="tur"
                                    label="Our-Events"
                                    placeholder=" Events Name In Turkish"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In Persian
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ir"
                                    label="Our-Events"
                                    placeholder=" Events Name In Persian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Name In Danish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="dk"
                                    label="Our-Events"
                                    placeholder=" Events Name In Danish"
                                />
                            </Form.Group>
                        </div>
                        {/* =====================================End Events Name=========================================== */}

                        <Form.Group>
                            <Form.Label htmlFor="KitchenImg">Events Image</Form.Label>
                            <div className="d-flex flex-column gap-2">
                                <Form.Control
                                    style={{
                                        border: "1px solid red",
                                        height: "fit-content"

                                    }}
                                    type="file"
                                    name="image"
                                    id="productImg"

                                    
                                />

                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="KitchenImg">
                                Events Images Gallery
                            </Form.Label>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="file"
                                    name="images"
                                    multiple
                                    id="productImg"
                                />

                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Events Video</Form.Label>
                            <Form.Control
                                style={{
                                    border: "1px solid red"
                                }}
                                type="file"
                                name="video"

                            />
                        </Form.Group>
                    </Col>

                    {/* =====================================Start Events Desc=========================================== */}

                    <Col>
                        <div className="activity-desc">
                            <h4>Events Description </h4>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In English
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    label="Our-Events"
                                    name="en" placeholder="Events Description In English"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In Arabic
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="ar"
                                    label="Our-Events" placeholder="Events Description In Arabic"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In French
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="fr"
                                    label="Our-Events" placeholder="Events Description In French"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In Russian
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="ru"
                                    label="Our-Events" placeholder="Events Description In Russian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In German
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="de"
                                    label="Our-Events" placeholder=" Events Description In Germany"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In China
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="cn"
                                    label="Our-Events" placeholder=" Events Description In China"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In Turkish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="tur"
                                    label="Our-Events" placeholder="Events Description In Turkish"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In Persian
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="ir"
                                    label="Our-Events" placeholder="Events Description In Persian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Description In Danish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    as="textarea"
                                    name="dk"
                                    label="Our-Events" placeholder="Events Description In Danish"
                                />
                            </Form.Group>
                        </div>
                    </Col>
                    <Col>
                        {/* ==========================End Events Desc============================== */}
                        {/* ==========================Start Events Location============================== */}
                        <div className="activity-location">
                            <h4>Events Location </h4>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In English
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="en"
                                    label="Our-Events"
                                    placeholder=" Events Location In English"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In Arabic
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ar"
                                    label="Our-Events"
                                    placeholder="Events Location In Arabic"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In French
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="fr"
                                    label="Our-Events"
                                    placeholder="Events Location In French"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In Russian
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ru"
                                    label="Our-Events"
                                    placeholder="Events Location In Russian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In German
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="de"
                                    label="Our-Events"
                                    placeholder="Events Location In German"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In China
                                </Form.Label>

                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="cn"
                                    label="Our-Events"
                                    placeholder="Events Location In China"
                                />
                            </Form.Group>
                            <Form.Group>
                                {" "}
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In Turkish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="tur"
                                    label="Our-Events"
                                    placeholder="Events Location In Turkish"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In Persian
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="ir"
                                    label="Our-Events"
                                    placeholder="Events Location In Persian"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="ProductNameInEn">
                                    Events Location In Danish
                                </Form.Label>
                                <Form.Control
                                    style={{
                                        border: "1px solid red"
                                    }}
                                    type="text"
                                    name="dk"
                                    label="Our-Events"
                                    placeholder="Events Location In Danish"
                                />
                            </Form.Group>
                            {/* ==========================End Events Location============================== */}


                            <Button variant="warning">
                                Add Events
                            </Button>

                        </div>
                    </Col>
                    <hr />
                </Row>
            </Form>
        </Row>
    )
}

export default AddEvents