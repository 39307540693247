import React, { useEffect, useState } from "react";
import axios from "axios";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//css
import "./CRAdmins.css";
import jwt_decode from "jwt-decode";
import { Roles } from "./AdminRoles";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
const CRAdmins = () => {
  const adminToken = localStorage.getItem("admin-token");
  const [idDelete,setIdDelete] = useState("")
  var decodedToken = jwt_decode(adminToken);
  const [admins, setAdmins] = useState([]);
  const [adminRoles, setAdminRoles] = useState([]);
  const [adminInfo, setAdminInfo] = useState({
    name: "",
    email: "",
    password: "",
    role: [],
  });

  //==============================Delete Admin Modal States==================================
  const [DeleteAdminModalshow, setDeleteAdminModalShow] = useState(false);
  const handleDeleteAdminModalShowClose = () => setDeleteAdminModalShow(false);
  const handleDeleteAdminModalShowShow = (id) => {
    setIdDelete(id)
    setDeleteAdminModalShow(true)
  };
  //=====================================Admin Roles Modal============================================

  const [AdminRolesModalShow, setAdminRolesModalShow] = useState(false);
  //=============================Handle Role===============================
  const checks = document.getElementsByClassName("form-check-input");
  //=======================================Check All Privilages====================================

  const checkallPrivilages = () => {
    const checkAll_check = document.getElementById("checkAll-check");
    if (checkAll_check.checked) {
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        element.checked = true;
        adminInfo.role.push(element.value);
      }
      adminInfo.role.shift();
    } else {
      adminInfo.role = [];
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        element.checked = false;
      }
    }
  };
  const handleRole = (e) => {
    if (e.target.checked) {
      if (!adminInfo.role.includes(e.target.value)) {
        setAdminInfo({
          ...adminInfo,
          role: [...adminInfo.role, e.target.value],
        });
      }
    } else {
      setAdminInfo({
        ...adminInfo,
        role: adminInfo.role.filter((item) => item !== e.target.value),
      });
    }
  };
  //=============================Handle Info===============================

  const handleInfo = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };
  //=============================Fetch Admins===============================
  useEffect(() => {
    const fetchAdmins = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/admin/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAdmins([]);
      } else {
        setAdmins(body.data);
      }
    };
    fetchAdmins();
  }, []);
  //=============================Add New Admin===============================
  const PostAdmin = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: adminInfo.name,
        email: adminInfo.email,
        password: adminInfo.password,
        role: adminInfo.role,
      });
      console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      if (
        adminInfo.name &&
        adminInfo.email &&
        adminInfo.password &&
        adminInfo.role.length > 0
      ) {
        const response = await fetch(
          "https://www.api.setalkel.co/admin/register",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          alert("Admin Not Created.....Sorry");
          console.log(body);
        } else {
          alert("Admin Has Been Created ....enjoy");
          window.location.reload();
        }
      } else {
        alert("Please Fill The Fields With Values To Create The Admin");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //==========================Delete Admin=====================
  const onDelete = (id) => {
  
    axios
      .delete(`https://www.api.setalkel.co/admin/${idDelete}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  function AdminRolesModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            fixedHeaderScrollHeight="100px"
            pagination
            responsive
            subHeaderWrap
            columns={Rolescolumns}
            data={adminRoles}
            customStyles={customStyles}
            highlightOnHover
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const Rolescolumns = [
    {
      name: "Role",
      selector: (row) => row,
    },
  ];
  //=================================================================================================================================================
  const columns = decodedToken.role.includes("u-admin-with-roles")
    ? [
      {
        name: "ID",
        selector: (row, index) => <span>{index}</span>,
      },
      {
        name: "Email",
        selector: (row) => <span>{row.email}</span>,
      },
      {
        name: "Name",
        selector: (row) => <span>{row.name}</span>,
      },
      {
        name: "Roles",
        selector: (row) => (
          <div>
            <Button
              variant="dark"
              onClick={() => {
                setAdminRolesModalShow(true);
                setAdminRoles(row.role);
              }}
            >
              View Admin Roles
            </Button>
            <AdminRolesModal
              show={AdminRolesModalShow}
              onHide={() => setAdminRolesModalShow(false)}
            />
          </div>
        ),
      },

      {
        name: "Update",
        selector: (row) => (
          <Link to={`/dashboard/cradmins/${row._id}`}>
            <Button variant="dark">Update</Button>
          </Link>
        ),
      },
      {
        name: "Delete",
        selector: (row) => (
          <>
            <Button variant="danger" onClick={() => handleDeleteAdminModalShowShow(row._id)}>
              Delete
            </Button>

            <Modal
              show={DeleteAdminModalshow}
              onHide={handleDeleteAdminModalShowClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are You Sure You Want To Delete This Admin ?
                </Modal.Title>
              </Modal.Header>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleDeleteAdminModalShowClose}
                >
                  Close
                </Button>
                <Button variant="danger" onClick={() => onDelete(row._id)}>
                  Delete Admin
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ),
      },
    ]
    : [
      //==================================================For Normal Admin=============================================
      {
        name: "ID",
        selector: (row, index) =>
          decodedToken._id === row._id && <span>{index}</span>,
      },
      {
        name: "Email",
        selector: (row) =>
          decodedToken._id === row._id && <span>{row.email}</span>,
      },
      {
        name: "Name",
        selector: (row) =>
          decodedToken._id === row._id && <span>{row.name}</span>,
      },
      {
        name: "Roles",
        selector: (row) =>
          decodedToken._id === row._id && (
            <div>
              <Button
                variant="dark"
                onClick={() => {
                  setAdminRolesModalShow(true);
                  setAdminRoles(row.role);
                }}
              >
                View Admin Roles
              </Button>
              <AdminRolesModal
                show={AdminRolesModalShow}
                onHide={() => setAdminRolesModalShow(false)}
              />
            </div>
          ),
      },

      {
        name: "Update",
        selector: (row) =>
          decodedToken._id === row._id && (
            <Link to={`/dashboard/cradmins/${row._id}`}>
              <Button variant="dark">Update</Button>
            </Link>
          ),
      },
      {
        name: "Delete",
        selector: (row) =>
          decodedToken.role.includes("d-admin") &&
          decodedToken.role.includes("super admin") &&
          decodedToken._id === row._id && (
            <>
              <Button
                variant="danger"
                onClick={() => handleDeleteAdminModalShowShow(row._id)}
              >
                Delete
              </Button>

              <Modal
                show={DeleteAdminModalshow}
                onHide={handleDeleteAdminModalShowClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Are You Sure You Want To Delete This Admin ?
                  </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleDeleteAdminModalShowClose}
                  >
                    Close
                  </Button>
                  <Button variant="danger" onClick={() => onDelete(row._id)}>
                    Delete Admin
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ),
      },
    ];
  const navigate = useNavigate()
  return (
    <div className="admins">
      <div className="title">
        <h1>CRUD Admins</h1>
      </div>
      <div className="d-flex gap-2">
        <Button variant="warning" onClick={() => {
          navigate("/dashboard/add-admin")
        }}>
          Add Admins
        </Button>
        <Button variant="danger">
          Export Excel
        </Button>
        <Button variant="danger">
          Import Excel
        </Button>
      </div>
      <hr />
      <Row>
        <div className="listAdmins">
          <DataTable
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={admins}
            customStyles={customStyles}
          />
        </div>
      </Row>
    </div>
  );
};

export default CRAdmins;
