import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//components
import AdminCartCard from "./AdminCartCard/AdminCartCard";
import CartPopUp from "../../../../../Components/ShipmentComponents/CartPopUp/CartPopUp";
//css
import "./AdminCart.css";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  adminClearCart,
  adminGetTotals,
} from "../../../../../redux/Admin/adminCartSlice";
//images
import EmptyBox from "../../../../../assets/Payments/empty cart.gif";
import AdminShipmentTotals from "../AdminShipmentTotals/AdminShipmentTotals";
import ConfirmImg from "../../../../../assets/suggestion confirm.gif";
import OverImg from "../../../../../assets/overweightclsoed.gif";
var deleteKey = require("key-del");
const AdminCart = () => {
  const adminCart = useSelector((state) => state.adminCart);
  const AdminShipmentData = useSelector(
    (state) => state.adminShipmentStepper.container
  );
  console.log(AdminShipmentData)
  const [CartMsgModalShow, setCartMsgModalShow] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shipmentOrder, setShipmentOrder] = useState({
    order: {
      items: [],
    },
    exportCountry: "",
    importCountry: "",
    state: "",
    container: "",
    deliveryMethod: "",
  });
  // const [updateShipmentOrder, setUpdateShipmentOrder] = useState([]);
  useEffect(() => {
    dispatch(adminGetTotals());
  }, [adminCart, dispatch]);

  //======================handleClearCart===============================

  const handleClearCart = () => {
    dispatch(adminClearCart());
  };
  //====================navigateToCartProducts===========================

  const navigateToCartProducts = () => {
    navigate("/dashboard/adminproducts");
  };

  //======================Add Shipment Suggestion========================
  const handleArrays = () => {
    const adminShipmentItems = adminCart.adminCartItems;
    var result;
    const Arr = [];
    for (let i = 0; i < adminShipmentItems.length; i++) {
      const element = adminShipmentItems[i];
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "productPrice",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "ratingValue",
        "updatedBy",
      ]);
      result["count"] = result["cartQuantity"];
      delete result["cartQuantity"];
      result["product"] = result["_id"];
      delete result["_id"];
      Arr.push(result);
    }
    setShipmentOrder({
      ...shipmentOrder,
      order: { ...shipmentOrder.order, items: Arr },
    });
  };

  useEffect(() => {
    handleArrays();
  }, [adminCart.adminCartItems]);
  //==========================================Update Shipment By Admin==============================
  const updateShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(shipmentOrder.order);
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/?shipmentId=${localStorage.getItem(
        "adminUpdatedSuggestionId"
      )}&agentId=${localStorage.getItem("adminUpdatedSuggestionAgentId")}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Suggestion Update Not Sent !!!");
      console.log(body);
    } else {
      setCartMsgModalShow(true);
      setTimeout(() => {
        localStorage.removeItem("adminShipmentItems");
        localStorage.removeItem("UpdatedShipmentItems");
        localStorage.removeItem("adminUpdatedShipmentItems");
        localStorage.removeItem("adminUpdatedCartItems");
        localStorage.removeItem("importCountryId");
        localStorage.removeItem("importStateId");
        localStorage.removeItem("exportCountryId");
        localStorage.removeItem("container");
        localStorage.removeItem("containerId");
        localStorage.removeItem("deliveryMethod");
        localStorage.removeItem("categoryId");
        dispatch(adminClearCart());
        navigate("/dashboard/suggestionshipments");
        window.location.reload();
      }, 2000);
    }
  };
  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
  // const goToAdminProducts = () => {
  //   sleep(1000);
  //   navigate("/dashboard/adminproducts");
  // };
  return (
    <section className="admin-cart-container">
      {adminCart.adminCartItems.length === 0 ? (
        <div className="admin-cart-empty">
          <img src={EmptyBox} alt="" />
          <p>Your cart is currently empty</p>
          <div className="admin-start-shopping">
            <button
              className="primary-btn"
              style={{ width: "100%" }}
              onClick={navigateToCartProducts}
            >
              Start Shopping
            </button>
          </div>
        </div>
      ) : (
        <div className="admin-cart-items">
          {adminCart.adminCartItems &&
            adminCart.adminCartItems.map((cartItem, index) => (
              <AdminCartCard key={index} cartItem={cartItem} />
            ))}
        </div>
      )}
      <div className="admin-cart-summary">
        {adminCart.adminCartTotalQuantity > 0 ? (
          <button className="clear-btn" onClick={() => handleClearCart()}>
            Clear Admin Cart
          </button>
        ) : (
          <button disabled>Clear Cart</button>
        )}
        <br />
        {adminCart.adminCartTotalQuantity > 0 &&
          adminCart.adminIsEditing && (
            <>
              <div className="admin-continue-shopping">
                <Link to="/dashboard/adminproducts">
                  <span>Continue Shopping</span>
                </Link>
              </div>
              <button
                className="primary-btn"
                onClick={() => {
                  updateShipment();
                }}
              >
                Update Shipment Suggestion
              </button>
              <CartPopUp
                img={ConfirmImg}
                message={
                  "Suggestion Has Been Updated Successfully And Sent To Agent"
                }
                CartMsgModalShow={CartMsgModalShow}
                setCartMsgModalShow={setCartMsgModalShow}
              />
            </>
          )}
        <AdminShipmentTotals />
      </div>
    </section>
  );
};

export default AdminCart;

// : adminCart.adminCartTotalQuantity > 0 &&
//           adminCart.adminShipmentInRange === false &&
//           adminCart.adminIsEditing ? (
//           <>
//             <div className="admin-continue-shopping">
//               <Link to="/dashboard/adminproducts">
//                 <span>Continue Shopping</span>
//               </Link>
//             </div>
//             <button
//               className="primary-btn"
//               onClick={() => {
//                 // updateShipment();
//                 setCartMsgModalShow(true);
//               }}
//             >
//               Update Shipment Suggestion
//             </button>
//             <CartPopUp
//               img={OverImg}
//               message={`Suggestion Could Not Be Updated Successfully And Sent To Agent , Weight Should Be Between ${AdminShipmentData.minWeightInState} and ${AdminShipmentData.maxWeightInState} and volume should be : ${AdminShipmentData?.container?.maxVolume ?AdminShipmentData?.container?.maxVolume : ""} `}
//               CartMsgModalShow={CartMsgModalShow}
//               setCartMsgModalShow={setCartMsgModalShow}
//             />
//           </>
//         ) : null