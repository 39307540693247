import React, { useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const icons = [
    'State', 'Account', 'Rating',
    'Agent', 'AboutUs', 'Product', 'ProductCat', 'Contact Us', 'Bites', 'Partners', 'ProductPrice', 'Delivery-method',
    'Activity', 'Offer', 'Containers', 'Countries', 'max-weight-container', 'Kitchen',
    'Admins', 'App Version', 'Pallet', 'Advertisements', 'Promotional Materials', 'Sessions', 'Shipment'
];

const mapPermissionsToRoles = (icon) => {
    return {
        name: icon,
        create: false,
        read: false,
        update: false,
        delete: false,
        show: false,
        approveCreate: false,
        approveUpdate: false,
        approveDelete: false
    };
};

const initialPermissions = icons.map(mapPermissionsToRoles);

const AddAdmins = () => {
    const [adminInfo, setAdminInfo] = useState({
        name: "",
        email: "",
        password: "",
        role: [],
    });

    const adminToken = localStorage.getItem("admin-token");
    var decodedToken = jwt_decode(adminToken);
    const [permissions, setPermissions] = useState(initialPermissions);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const handleCheckboxChange = (iconName, permission) => {
        setPermissions(prevPermissions =>
            prevPermissions.map(icon =>
                icon.name === iconName
                    ? { ...icon, [permission]: !icon[permission] }
                    : icon
            )
        );

        const permissionShortcuts = {
            create: 'c',
            read: 'r',
            update: 'u',
            delete: 'd',
            show: 's',
            approveCreate: 'ac',
            approveUpdate: 'au',
            approveDelete: 'ad'
        };

        const permissionString = `${permissionShortcuts[permission]}-${iconName.toLowerCase()}`;

        setSelectedPermissions(prevSelected => {
            if (prevSelected.includes(permissionString)) {
                return prevSelected.filter(p => p !== permissionString);
            } else {
                return [...prevSelected, permissionString];
            }
        });
    };

    const handleInfo = (e) => {
        const { name, value } = e.target;
        setAdminInfo({ ...adminInfo, [name]: value });
    };

    // دالة لتحديد الكل
    const selectAllPermissions = () => {
        const updatedPermissions = permissions.map(icon => ({
            ...icon,
            create: true,
            read: true,
            update: true,
            delete: true,
            show: true,
            approveCreate: true,
            approveUpdate: true,
            approveDelete: true
        }));
        setPermissions(updatedPermissions);

        const allPermissions = updatedPermissions.flatMap(icon => [
            `c-${icon.name.toLowerCase()}`,
            `r-${icon.name.toLowerCase()}`,
            `u-${icon.name.toLowerCase()}`,
            `d-${icon.name.toLowerCase()}`,
            `s-${icon.name.toLowerCase()}`,
            `ac-${icon.name.toLowerCase()}`,
            `au-${icon.name.toLowerCase()}`,
            `ad-${icon.name.toLowerCase()}`
        ]);

        setSelectedPermissions(allPermissions);
    };

    // دالة لإلغاء تحديد الكل
    const deselectAllPermissions = () => {
        const updatedPermissions = permissions.map(icon => ({
            ...icon,
            create: false,
            read: false,
            update: false,
            delete: false,
            show: false,
            approveCreate: false,
            approveUpdate: false,
            approveDelete: false
        }));
        setPermissions(updatedPermissions);
        setSelectedPermissions([]);
    };
    const navigate = useNavigate()
    const PostAdmin = async (e) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                `bearer ${localStorage.getItem("admin-token")}`
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                name: adminInfo.name,
                email: adminInfo.email,
                password: adminInfo.password,
                role: selectedPermissions,
            });
            console.log(raw);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            if (
                adminInfo.name &&
                adminInfo.email &&
                adminInfo.password && 
                selectedPermissions.length > 0

            ) {
                const response = await fetch(
                    // role must be a, b or super admin
                    "https://www.api.setalkel.co/admin/register",
                    requestOptions
                );
                const body = await response.json();
                if (!response.ok) {
                    alert("Admin Not Created.....Sorry");
                    console.log(body);
                } else {
                    alert("Admin Has Been Created ....enjoy");
                    window.location.reload();
                    navigate("/dashboard/cradmins")
                }
            } else {
                alert("Please Fill The Fields With Values To Create The Admin");
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="container mt-4">
            <div className="title">
                <h1>CRUD Admins</h1>
            </div>
            <div className="create-admin">
                {decodedToken.role.includes("u-admin-with-roles") && (
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter User Name"
                                required
                                onChange={handleInfo}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="Enter Password"
                                required
                                onChange={handleInfo}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                required
                                placeholder="Enter Admin email"
                                onChange={handleInfo}
                            />
                        </Form.Group>
                    </Form>
                )}
            </div>
            <div className="mb-3 d-flex gap-2">
                <Button variant="primary" className="me-2" onClick={selectAllPermissions}>
                    Select All
                </Button>
                <Button variant="danger" onClick={deselectAllPermissions}>
                    Cancel All
                </Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Icon Name</th>
                        <th>Read</th>
                        <th>Show</th>
                        <th>Create</th>
                        <th style={{ border: ' 1px solid red', color: 'black' }}>Approve for Create</th>
                        <th>Update</th>
                        <th style={{ border: ' 1px solid red', color: 'black' }}>Approve for Update</th>
                        <th>Delete</th>
                        <th style={{ border: ' 1px solid red', color: 'black' }}>Approve for Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {permissions.map(icon => (
                        <tr key={icon.name}>
                            <td>{icon.name}</td>
                            {['read', 'show', 'create', 'approveCreate', 'update', 'approveUpdate', 'delete', 'approveDelete'].map(permission => (
                                <td key={permission} style={permission.includes('approve') ? { border: ' 1px solid red', color: 'black' } : {}}>
                                    <Form.Check
                                        type="checkbox"
                                        checked={icon[permission]}
                                        onChange={() => handleCheckboxChange(icon.name, permission)}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button variant="warning" onClick={PostAdmin}>
                Create New Admin
            </Button>
        </div>
    );
};

export default AddAdmins;
