import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { customStyles } from '../constants/DataTableCustomeStyles';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Address = () => {
    const [address, setAdress] = useState([])
    const [id, setId] = useState("")
    const [DeleteModalShow, setDeleteModal] = useState(false);
    const handleClose = () => setDeleteModal(false);
    const handleShow = () => setDeleteModal(true);
    const fetchAddress = async () => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",

        };

        const response = await fetch(
            "https://www.api.setalkel.co/address",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            setAdress([]);

        } else {
            setAdress(body);
            console.log(body);
        }
    };
    useEffect(() => {

        fetchAddress();
    }, []);
    const onDelete = () => {
        axios
            .delete(`https://www.api.setalkel.co/address/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("admin-token"),
                },
            })
            .then((response) => {
                if (response.status !== 204) {
                    // console.log(response.data.msg);
                } else {
                    toast.success("item deleted");
                    handleClose()
                    fetchAddress()
                }
            })
            .catch((error) => {
                toast.error(error.response)
                // console.log("error", error.response);
            });
    };
    const navigate = useNavigate()
    const columns = [
        {
            name: "Address In En",
            selector: (row) => <span>{row.address?.en}</span>,
        },
        {
            name: "Address In ar",
            selector: (row) => <span>{row.address?.en}</span>,
        },
        {
            name: "Country In En",
            selector: (row) => <span>{row.country?.en}</span>,
        },
        {
            name: "Country In Ar",
            selector: (row) => <span>{row.country?.ar}</span>,
        },
        {
            name: "State In En",
            selector: (row) => <span>{row.state?.en}</span>,
        },
        {
            name: "State In Ar",
            selector: (row) => <span>{row.state?.ar}</span>,
        },
        {
            name: "Email1",
            selector: (row) => <span>{row.email1}</span>,
        },
        {
            name: "Email2",
            selector: (row) => <span>{row.email2}</span>,
        },
        {
            name: "Phone1",
            selector: (row) => <span>{row.phone1}</span>,
        },
        {
            name: "Phone2",
            selector: (row) => <span>{row.phone2}</span>,
        },
        {
            name: "Show/Hidden",
            selector: (row) => <span>{row.hidden ? "Show" : "Hidden"}</span>,
        },
        {
            name: "Delete",
            selector: (row) => (
                <div>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleShow();
                            setId(row._id);
                        }}
                    >
                        Delete
                    </Button>


                </div>
            ),
        },
        {
            name: "Update",
            selector: (row) => (
                <div>
                    <Button
                        variant="warning"
                        onClick={() => {
                            navigate(`/dashboard/address/${row._id}`)
                        }}
                    >
                        Update
                    </Button>


                </div>
            ),
        },
    ]
    return (
        <>
            <Row>
                <div className='d-flex align-items-center'>
                    <Button variant="warning" id="verify-btn" >
                        Export Excel File for Address
                    </Button>
                    {/* <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            Download Excel File
          </Button> */}
                    <Button variant="danger" id="verify-btn" >
                        Import Excel
                    </Button>
                    <Button variant="warning" id="verify-btn">
                        Show Hide Address
                    </Button>
                  
                </div>
            </Row>
            <Row>
                <div className="list-category">
                    <DataTable
                        fixedHeaderScrollHeight="300px"
                        responsive
                        subHeaderWrap
                        columns={columns}
                        data={address}
                        customStyles={customStyles}
                    />
                </div>
            </Row>
            <Modal
                size="lg"
                show={DeleteModalShow}
                onHide={() => setDeleteModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Are You Sure You Want To Delete This Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onDelete()}>
                        Delete Address
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Address