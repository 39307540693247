import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//css
import "./AdminCartCard.css";
//bootstrap
import { Col, Row } from "react-bootstrap";
//icons
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
//images
// import EditImg from "../../../../../../assets/Payments/edit.png";
import TrashImg from "../../../../../../assets/Payments/trash.png";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  adminAddAmountToCart,
  adminAddToCart,
  adminDecreaseCart,
  adminRemoveFromCart,
} from "../../../../../../redux/Admin/adminCartSlice";
//URL
const AdminCartCard = ({ cartItem }) => {
  const AdminCart = useSelector((state) => state.adminCart);
  const dispatch = useDispatch();
  //=======================Check Over Load============================
  useEffect(() => {
    document.getElementById("addQuantity").disabled = "true";
  }, [AdminCart.overLoad]);
  //=======================add Amount Handle============================

  const handleChange = (e, cartItem) => {
    if (e.target.value > 0) {
      dispatch(
        adminAddAmountToCart({
          amount: parseInt(e.target.value),
          cartItem: cartItem,
        })
      );
    }
  };
  //=============================Add To Cart=================================
  const handleAddToCart = (cartItem) => {
    if (AdminCart.adminOverload) {
      alert(
        "Container Weight Or Volume Has Exceeded The Maximum Please Decrease The Quantity"
      );
    } else {
      dispatch(adminAddToCart(cartItem));
    }
  };
  //=============================Decrease From Cart=================================

  const handleDecreaseCart = (cartItem) => {
    dispatch(adminDecreaseCart(cartItem));
  };
  //=============================Remove From Cart=================================

  const handleRemoveFromCart = (cartItem) => {
    dispatch(adminRemoveFromCart(cartItem));
  };


  // State for quantities
  const [piceQty, setPiceQty] = useState(cartItem.piceQty || 0);
  const [boxQty, setBoxQty] = useState(cartItem.boxQty || 0);
  const [palletQty, setPalletQty] = useState(cartItem.palletQty || 0);

  // Constants for conversion rates
  const piecesPerBox = 12;
  const boxesPerPallet = 110;

  // Update boxQty when piceQty changes
  useEffect(() => {
    setBoxQty(Math.floor(piceQty / piecesPerBox));
  }, [piceQty]);
  useEffect(() => {
    setPiceQty(boxQty * piecesPerBox);
    setPalletQty(Math.floor(boxQty / boxesPerPallet));
  }, [boxQty]);

  useEffect(() => {
    setBoxQty(palletQty * boxesPerPallet);
  }, [palletQty]);

  return (
    <article className="admin-cart-card" key={cartItem._id}>
      <div className="admin-cart-card-section1">
        <div className="admin-cart-card-h1">
          <div className="admin-cart-card-title">
            <div className="admin-cart-card-dropdown">
              <button className="admin-cart-card-dropbtn">...</button>
              <div className="admin-cart-card-dropdown-content">
                <Link
                  to="#"
                  style={{ color: "rgba(255, 127, 82, 1)" }}
                  onClick={() => handleRemoveFromCart(cartItem)}
                >
                  Delete <img src={TrashImg} alt="trash" />
                </Link>
              </div>
            </div>
            <div className="admin-cart-card-title-info">
              <h3>{cartItem.subCat.en}</h3>
              <p>{cartItem.subCat.en}</p>
            </div>
            <div className="admin-cart-card-title-totals">
              <span>Sub-Total Price</span>
              <span>{`${cartItem.productPrice * cartItem.cartQuantity
                ? (cartItem.productPrice * cartItem.cartQuantity).toFixed(2)
                : 0
                } $`}</span>
            </div>
          </div>
          <hr />
          <div className="admin-cart-card-details">
            <div className="admin-info-data">
              <div>
                <Row>
                  <Col>Piece Gross Weight</Col>
                  <Col>{`${cartItem.peiceQty.grossWeightForPeice
                    ? cartItem.peiceQty.grossWeightForPeice
                    : 0
                    } ${cartItem.peiceQty.unit}`}</Col>
                </Row>
                <Row>
                  <Col>Package Gross Weight</Col>
                  <Col>{`${cartItem.packageGrossWeight
                    ? cartItem.packageGrossWeight
                    : 0
                    }`}</Col>
                </Row>
                <Row>
                  <Col>Box Volume</Col>
                  <Col>{cartItem.volume ? cartItem.volume : 0}</Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>Box Price</Col>
                  <Col>{cartItem.productPrice ? cartItem.productPrice : 0}</Col>
                </Row>
                <Row>
                  <Col>Count In Box</Col>
                  <Col>{cartItem.countInBox ? cartItem.countInBox : 0}</Col>
                </Row>
              </div>
            </div>
            <div className="d-flex flex-column ">
              <div className="admin-cart-card-qty ">
                <p>Package Quantity</p>
                <div className="btn-group">
                  <button
                    onClick={() => handleAddToCart(cartItem)}
                    id="addQuantity"
                  >
                    <AiOutlinePlus />
                  </button>
                  <input
                    type="text"
                    name="quantity"
                    id="quantity"
                    placeholder="QTY"
                    maxLength="4"
                    value={cartItem.cartQuantity}
                    onChange={(e) => handleChange(e, cartItem)}
                  />
                  <button
                    onClick={() => handleDecreaseCart(cartItem)}
                    id="subtracktQuantity"
                  >
                    <AiOutlineMinus />
                  </button>
                </div>
              </div>
              <div className="d-flex">
                <div className="admin-cart-card-qty">
                  <p>Pice Quantity</p>
                  <div className="btn-group red">
                    <button onClick={() => setPiceQty(piceQty + 1)}>
                      <AiOutlinePlus />
                    </button>
                    <input
                      type="text"
                      value={piceQty}
                      onChange={(e) => setPiceQty(parseInt(e.target.value) || 0)}
                    />
                    <button onClick={() => setPiceQty(Math.max(0, piceQty - 1))}>
                      <AiOutlineMinus />
                    </button>
                  </div>
                </div>
                <div className="admin-cart-card-qty">
                  <p>Box Quantity</p>
                  <div className="btn-group red">
                    <button onClick={() => setBoxQty(boxQty + 1)}>
                      <AiOutlinePlus />
                    </button>
                    <input
                      type="text"
                      value={boxQty}
                      onChange={(e) => setBoxQty(parseInt(e.target.value) || 0)}
                    />
                    <button onClick={() => setBoxQty(Math.max(0, boxQty - 1))}>
                      <AiOutlineMinus />
                    </button>
                  </div>
                </div>
                <div className="admin-cart-card-qty ">
                  <p>Pallet Quantity</p>
                  <div className="btn-group red">
                    <button onClick={() => setPalletQty(palletQty + 1)}>
                      <AiOutlinePlus />
                    </button>
                    <input
                      type="text"
                      value={palletQty}
                      onChange={(e) => setPalletQty(parseInt(e.target.value) || 0)}
                    />
                    <button onClick={() => setPalletQty(Math.max(0, palletQty - 1))}>
                      <AiOutlineMinus />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="admin-cart-card-h2">
          <img src={"https://www.api.setalkel.co" + cartItem.img} alt="img" />
        </div>
      </div>
    </article>
  );
};

export default AdminCartCard;






