import React, { useState } from 'react'
import { Button, Form, Modal, Row } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddBanner = () => {
    const navigate = useNavigate()
    const [values, setValues] = useState({
        desc: {
            ar: "",
            en: "",
            de: "",
            fr: "",
            cn: "",
            ru: "",
            tur: "",
            ir: "",
            dk: "",
        },
    });
    const [ImgGallery, setImgGallery] = useState({
        ImgGallery: [],
    });
    const [Videofile, setVideofile] = useState({
        Videofile: null,
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            desc: { ...values.desc, [name]: value },
        });
    };
    const handleGalleryChange = (e) => {
        const newImages = Array.from(e.target.files);

        setImgGallery(prevState => {
            const allImages = [...prevState.ImgGallery, ...newImages];
            return {
                ImgGallery: allImages
            };
        });
    };
    // console.log(ImgGallery)
    const handleVideoChange = (e) => {
        setVideofile({
            Videofile: e.target.files[0],
        });
    };

    const PostAboutUs = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = {
            desc: {
                ar: values.desc.ar,
                en: values.desc.en,
                de: values.desc.de,
                fr: values.desc.fr,
                cn: values.desc.cn,
                ru: values.desc.ru,
                tur: values.desc.tur,
                ir: values.desc.ir,
                dk: values.desc.dk,
            },
        };
        let newDesc = values.desc;
        let newRaw = raw;
        Object.keys(newDesc).forEach((key) => {
            if (newDesc[key] === "" || newDesc[key] === "undefined") {
                delete newDesc[key];
            }
        });
        newRaw.desc = newDesc;
        var raw2 = JSON.stringify(newRaw);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw2,
            redirect: "follow",
        };

        const response = await fetch(
            "https://www.api.setalkel.co/aboutUs",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            toast.error("There Is An Error");
            console.log(response);
            console.log(body);
        } else {
            toast.success("About Us Has Been Added");
            setValues({
                desc: {
                    ar: "",
                    en: "",
                    de: "",
                    fr: "",
                    cn: "",
                    ru: "",
                    tur: "",
                    ir: "",
                    dk: "",
                },
            });
            navigate("/dashboard/craboutUs")
        }
    };
    return (
        <>
            <Row className='text-center mb-3'><h2>Add Banner Description</h2></Row>
            <Row>
                <div className="create-about-us">
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>English textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="en"
                                value={values.desc.en}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Arabic textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="ar"
                                value={values.desc.ar}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>French textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="fr"
                                value={values.desc.fr}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Russian textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="ru"
                                value={values.desc.ru}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Chinese textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="cn"
                                value={values.desc.cn}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>German textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="de"
                                value={values.desc.de}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Turkesh textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="tur"
                                value={values.desc.tur}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Persian textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="ir"
                                value={values.desc.ir}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Danish textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{
                                    border: "1px solid red"
                                }}
                                rows={3}
                                name="dk"
                                value={values.desc.dk}
                                onChange={handleInputChange}
                            />
                        </Form.Group>


                    </Form>
                    <div className='btn'>    <Button variant="warning">
                        Add AddBanner
                    </Button>
                    </div>

                </div>
            </Row>
        </>
    )
}

export default AddBanner